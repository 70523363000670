import React from 'react';
import ReactPlayer from 'react-player'
import ReactHtmlParser from 'react-html-parser';

import TextHeader from '../../../../components/UI/TextHeader/TextHeader';

import TransCap from '../../../../components/TransCap/TransCap';

import classes from './ProjectDetails.module.css';

const projectDetails = props => {

    const tags = props.tags.split('/');

    return (
        <div className={classes.ProjectDetails}>
            <TextHeader text={props.title} />
            {
                tags.map(t => (
                    <div
                        key={t}
                        className={classes.Tag}>
                        <i className="fa fa-tag" aria-hidden="true"></i> {t}
                    </div>
                ))
            }
            <div className="clearfix"></div>
            <p>
                <strong><TransCap text='projectType' />: </strong> {props.type}
            </p>
            <p>
                <strong>GitHub URL: </strong>
                {
                props.githubUrl 
                 ? <a href={props.githubUrl} target="_blank" rel="noopener noreferrer" >{props.githubUrl}</a>
                 : <span className="text-warning small"><TransCap text="unavailable" /></span>
                }
            </p>
            <p>
                <strong>Description: </strong><br />
                <div>{ ReactHtmlParser(props.description) }</div>
            </p>
            <p>
                <strong>Demo</strong>
            </p>

            {
                props.demoUrl 
                ?<ReactPlayer
                url={props.demoUrl} 
                controls 
                light
                width="100%"
                pip />
                : <h3 className="text-center text-warning"> <TransCap text="unavailable" /> </h3>
            }

        </div>
    );
}

export default projectDetails;