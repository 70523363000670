import React from 'react';

import classes from './CircleChart.module.css'


const circleChart = props => {

    const clsChart = [classes["c100"]];
    const clsSize = classes[props.size];
    const val = props.value;

    const clsValName = "p" + val;
    const clsValue = classes[clsValName];

    let clsColorName = "green";

    if (val >= 0 && val <= 25) {
        clsColorName = "orange";
    } else if (val > 25 && val <= 75) {
        clsColorName = "blue";
    } else if (val > 75 && val <= 100) {
        clsColorName = "green";
    } else {
        clsColorName = "dark";
    }

    const clsColor = classes[clsColorName];

    clsChart.push(clsValue);
    clsChart.push(clsColor);
    clsChart.push(clsSize);

    return (
        <div style={{ width: '100%' }}>
            <div className={clsChart.join(' ')}>
                <span>{props.text}</span>
                <div className={classes["slice"]}>
                    <div className={classes["bar"]}></div>
                    <div className={classes["fill"]}></div>
                </div>
            </div>
        </div>
    );
}

export default circleChart;