
// Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// CV Personal Information
export const PERSONAL_INFO_UPDATE_START = 'PERSONAL_INFO_UPDATE_START';
export const PERSONAL_INFO_UPDATE_SUCCESS = 'PERSONAL_INFO_UPDATE_SUCCESS';
export const PERSONAL_INFO_UPDATE_FAIL = 'PERSONAL_INFO_UPDATE_FAIL';

export const PERSONAL_INFO_FETCH_START = 'PERSONAL_INFO_FETCH_START';
export const PERSONAL_INFO_FETCH_SUCCESS = 'PERSONAL_INFO_FETCH_SUCCESS';
export const PERSONAL_INFO_FETCH_FAIL = 'PERSONAL_INFO_FETCH_FAIL';

export const PERSONAL_INFO_ABOUTME_FETCH_START = 'PERSONAL_INFO_ABOUTME_FETCH_START';
export const PERSONAL_INFO_ABOUTME_FETCH_SUCCESS = 'PERSONAL_INFO_ABOUTME_FETCH_SUCCESS';
export const PERSONAL_INFO_ABOUTME_FETCH_FAIL = 'PERSONAL_INFO_ABOUTME_FETCH_FAIL';

export const PERSONAL_INFO_UPLOADCV_START = 'PERSONAL_INFO_UPLOADCV_START';
export const PERSONAL_INFO_UPLOADCV_PROGRESS = 'PERSONAL_INFO_UPLOADCV_PROGRESS';
export const PERSONAL_INFO_UPLOADCV_SUCCESS = 'PERSONAL_INFO_UPLOADCV_SUCCESS';
export const PERSONAL_INFO_UPLOADCV_FAIL = 'PERSONAL_INFO_UPLOADCV_FAIL';

export const PERSONAL_INFO_FETCH_CVS = 'PERSONAL_INFO_FETCH_CVS';
export const PERSONAL_INFO_FETCH_CVS_FAIL = 'PERSONAL_INFO_FETCH_CVS_FAIL';

export const PERSONAL_INFO_DELETE_CV = 'PERSONAL_INFO_DELETE_CV';
export const PERSONAL_INFO_DELETE_CV_FAIL = 'PERSONAL_INFO_DELETE_CV_FAIL';



// CV Contact Information
export const CONTACT_INFO_UPDATE_START = 'CONTACT_INFO_UPDATE_START';
export const CONTACT_INFO_UPDATE_SUCCESS = 'CONTACT_INFO_UPDATE_SUCCESS';
export const CONTACT_INFO_UPDATE_FAIL = 'CONTACT_INFO_UPDATE_FAIL';

export const CONTACT_INFO_FETCH_START = 'CONTACT_INFO_FETCH_START';
export const CONTACT_INFO_FETCH_SUCCESS = 'CONTACT_INFO_FETCH_SUCCESS';
export const CONTACT_INFO_FETCH_FAIL = 'CONTACT_INFO_FETCH_FAIL';

// CV Experiences
export const EXPERIENCE_ADD_INIT = 'EXPERIENCE_ADD_INIT';
export const EXPERIENCE_ADD_START = 'EXPERIENCE_ADD_START';
export const EXPERIENCE_ADD_SUCCESS = 'EXPERIENCE_ADD_SUCCESS';
export const EXPERIENCE_ADD_FAIL = 'EXPERIENCE_ADD_FAIL';

export const EXPERIENCE_UPDATE_SUCCESS = 'EXPERIENCE_UPDATE_SUCCESS';

export const EXPERIENCE_FETCH_START = 'EXPERIENCE_FETCH_START';
export const EXPERIENCE_FETCH_SUCCESS = 'EXPERIENCE_FETCH_SUCCESS';
export const EXPERIENCE_FETCH_FAIL = 'EXPERIENCE_FETCH_FAIL';

export const EXPERIENCE_DELETE_START = 'EXPERIENCE_DELETE_START';
export const EXPERIENCE_DELETE_SUCCESS = 'EXPERIENCE_DELETE_SUCCESS';
export const EXPERIENCE_DELETE_FAIL = 'EXPERIENCE_DELETE_FAIL';

export const EXPERIENCE_FINDBYID_START = 'EXPERIENCE_FINDBYID_START';
export const EXPERIENCE_FINDBYID_SUCCESS = 'EXPERIENCE_FINDBYID_SUCCESS';
export const EXPERIENCE_FINDBYID_FAIL = 'EXPERIENCE_FINDBYID_FAIL';

export const EXPERIENCE_UPLOADFILE_START = 'EXPERIENCE_UPLOADFILE_START';
export const EXPERIENCE_UPLOADFILE_PROGRESS = 'EXPERIENCE_UPLOADFILE_PROGRESS';
export const EXPERIENCE_UPLOADFILE_SUCCESS = 'EXPERIENCE_UPLOADFILE_SUCCESS';
export const EXPERIENCE_UPLOADFILE_FAIL = 'EXPERIENCE_UPLOADFILE_FAIL';

export const EXPERIENCE_DELETE_FILE_START = 'EXPERIENCE_DELETE_FILE_START';
export const EXPERIENCE_DELETE_FILE_SUCCESS = 'EXPERIENCE_DELETE_FILE_SUCCESS';
export const EXPERIENCE_DELETE_FILE_FAIL = 'EXPERIENCE_DELETE_FILE_FAIL';


// CV Education
export const EDUCATION_ADD_INIT = 'EDUCATION_ADD_INIT';
export const EDUCATION_ADD_START = 'EDUCATION_ADD_START';
export const EDUCATION_ADD_SUCCESS = 'EDUCATION_ADD_SUCCESS';
export const EDUCATION_ADD_FAIL = 'EDUCATION_ADD_FAIL';

export const EDUCATION_UPDATE_SUCCESS = 'EDUCATION_UPDATE_SUCCESS';

export const EDUCATION_FETCH_START = 'EDUCATION_FETCH_START';
export const EDUCATION_FETCH_SUCCESS = 'EDUCATION_FETCH_SUCCESS';
export const EDUCATION_FETCH_FAIL = 'EDUCATION_FETCH_FAIL';

export const EDUCATION_DELETE_START = 'EDUCATION_DELETE_START';
export const EDUCATION_DELETE_SUCCESS = 'EDUCATION_DELETE_SUCCESS';
export const EDUCATION_DELETE_FAIL = 'EDUCATION_DELETE_FAIL';

export const EDUCATION_FINDBYID_START = 'EDUCATION_FINDBYID_START';
export const EDUCATION_FINDBYID_SUCCESS = 'EDUCATION_FINDBYID_SUCCESS';
export const EDUCATION_FINDBYID_FAIL = 'EDUCATION_FINDBYID_FAIL';

export const EDUCATION_UPLOADFILE_START = 'EDUCATION_UPLOADFILE_START';
export const EDUCATION_UPLOADFILE_PROGRESS = 'EDUCATION_UPLOADFILE_PROGRESS';
export const EDUCATION_UPLOADFILE_SUCCESS = 'EDUCATION_UPLOADFILE_SUCCESS';
export const EDUCATION_UPLOADFILE_FAIL = 'EDUCATION_UPLOADFILE_FAIL';

export const EDUCATION_DELETE_FILE_START = 'EDUCATION_UPLOADFILE_FAIL';
export const EDUCATION_DELETE_FILE_SUCCESS = 'EDUCATION_DELETE_FILE_SUCCESS';
export const EDUCATION_DELETE_FILE_FAIL = 'EDUCATION_DELETE_FILE_FAIL';

// CV Languages
export const LANGUAGE_ADD_INIT = 'LANGUAGE_ADD_INIT';
export const LANGUAGE_ADD_START = 'LANGUAGE_ADD_START';
export const LANGUAGE_ADD_SUCCESS = 'LANGUAGE_ADD_SUCCESS';
export const LANGUAGE_ADD_FAIL = 'LANGUAGE_ADD_FAIL';

export const LANGUAGE_UPDATE_SUCCESS = 'LANGUAGE_UPDATE_SUCCESS';

export const LANGUAGE_FETCH_START = 'LANGUAGE_FETCH_START';
export const LANGUAGE_FETCH_SUCCESS = 'LANGUAGE_FETCH_SUCCESS';
export const LANGUAGE_FETCH_FAIL = 'LANGUAGE_FETCH_FAIL';

export const LANGUAGE_DELETE_START = 'LANGUAGE_DELETE_START';
export const LANGUAGE_DELETE_SUCCESS = 'LANGUAGE_DELETE_SUCCESS';
export const LANGUAGE_DELETE_FAIL = 'LANGUAGE_DELETE_FAIL';

export const LANGUAGE_FINDBYID_START = 'LANGUAGE_FINDBYID_START';
export const LANGUAGE_FINDBYID_SUCCESS = 'LANGUAGE_FINDBYID_SUCCESS';
export const LANGUAGE_FINDBYID_FAIL = 'LANGUAGE_FINDBYID_FAIL';

export const LANGUAGE_UPLOADFILE_START = 'LANGUAGE_UPLOADFILE_START';
export const LANGUAGE_UPLOADFILE_PROGRESS = 'LANGUAGE_UPLOADFILE_PROGRESS';
export const LANGUAGE_UPLOADFILE_SUCCESS = 'LANGUAGE_UPLOADFILE_SUCCESS';
export const LANGUAGE_UPLOADFILE_FAIL = 'LANGUAGE_UPLOADFILE_FAIL';

export const LANGUAGE_DELETE_FILE_START = 'LANGUAGE_DELETE_FILE_START';
export const LANGUAGE_DELETE_FILE_SUCCESS = 'LANGUAGE_DELETE_FILE_SUCCESS';
export const LANGUAGE_DELETE_FILE_FAIL = 'LANGUAGE_DELETE_FILE_FAIL';


// CV Traning Courses
export const COURSE_ADD_INIT = 'COURSE_ADD_INIT';
export const COURSE_ADD_START = 'COURSE_ADD_START';
export const COURSE_ADD_SUCCESS = 'COURSE_ADD_SUCCESS';
export const COURSE_ADD_FAIL = 'COURSE_ADD_FAIL';

export const COURSE_UPDATE_SUCCESS = 'COURSE_UPDATE_SUCCESS';

export const COURSE_FETCH_START = 'COURSE_FETCH_START';
export const COURSE_FETCH_SUCCESS = 'COURSE_FETCH_SUCCESS';
export const COURSE_FETCH_FAIL = 'COURSE_FETCH_FAIL';

export const COURSE_DELETE_START = 'COURSE_DELETE_START';
export const COURSE_DELETE_SUCCESS = 'COURSE_DELETE_SUCCESS';
export const COURSE_DELETE_FAIL = 'COURSE_DELETE_FAIL';

export const COURSE_FINDBYID_START = 'COURSE_FINDBYID_START';
export const COURSE_FINDBYID_SUCCESS = 'COURSE_FINDBYID_SUCCESS';
export const COURSE_FINDBYID_FAIL = 'COURSE_FINDBYID_FAIL';

export const COURSE_UPLOADFILE_START = 'COURSE_UPLOADFILE_START';
export const COURSE_UPLOADFILE_PROGRESS = 'COURSE_UPLOADFILE_PROGRESS';
export const COURSE_UPLOADFILE_SUCCESS = 'COURSE_UPLOADFILE_SUCCESS';
export const COURSE_UPLOADFILE_FAIL = 'COURSE_UPLOADFILE_FAIL';

export const COURSE_DELETE_FILE = 'COURSE_DELETE_FILE';
export const COURSE_DELETE_FILE_FAIL = 'COURSE_DELETE_FILE_FAIL';


// CV Skills
export const SKILL_ADD_INIT = 'SKILL_ADD_INIT';
export const SKILL_ADD_START = 'SKILL_ADD_START';
export const SKILL_ADD_SUCCESS = 'SKILL_ADD_SUCCESS';
export const SKILL_ADD_FAIL = 'SKILL_ADD_FAIL';

export const SKILL_UPDATE_SUCCESS = 'SKILL_UPDATE_SUCCESS';

export const SKILL_FETCH_START = 'SKILL_FETCH_START';
export const SKILL_FETCH_SUCCESS = 'SKILL_FETCH_SUCCESS';
export const SKILL_FETCH_FAIL = 'SKILL_FETCH_FAIL';

export const SKILL_DELETE_START = 'SKILL_DELETE_START';
export const SKILL_DELETE_SUCCESS = 'SKILL_DELETE_SUCCESS';
export const SKILL_DELETE_FAIL = 'SKILL_DELETE_FAIL';

export const SKILL_FINDBYID_START = 'SKILL_FINDBYID_START';
export const SKILL_FINDBYID_SUCCESS = 'SKILL_FINDBYID_SUCCESS';
export const SKILL_FINDBYID_FAIL = 'SKILL_FINDBYID_FAIL';

// Main
export const MAIN_INFO_FETCH_START = 'MAIN_INFO_FETCH_START';
export const MAIN_INFO_FETCH_SUCCESS = 'MAIN_INFO_FETCH_SUCCESS';
export const MAIN_INFO_FETCH_FAIL = 'MAIN_INFO_FETCH_FAIL';


// Messages
export const MESSAGE_SEND_INIT = 'MESSAGE_SEND_INIT';
export const MESSAGE_SEND_START = 'MESSAGE_SEND_START';
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS';
export const MESSAGE_SEND_FAIL = 'MESSAGE_SEND_FAIL';

export const MESSAGES_FETCH_START = 'MESSAGES_FETCH_START';
export const MESSAGES_FETCH_SUCCESS = 'MESSAGES_FETCH_SUCCESS';
export const MESSAGES_FETCH_FAIL = 'MESSAGES_FETCH_FAIL';

export const MESSAGE_FINDBYID_START = 'MESSAGE_FINDBYID_START';
export const MESSAGE_FINDBYID_SUCCESS = 'MESSAGE_FINDBYID_SUCCESS';
export const MESSAGE_FINDBYID_FAIL = 'MESSAGE_FINDBYID_FAIL';

export const MESSAGE_DELETE_START = 'MESSAGE_DELETE_START';
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS';
export const MESSAGE_DELETE_FAIL = 'MESSAGE_DELETE_FAIL';

// projects
export const PROJECT_ADD_INIT = 'PROJECT_ADD_INIT';
export const PROJECT_ADD_START = 'PROJECT_ADD_START';
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS';
export const PROJECT_ADD_FAIL = 'PROJECT_ADD_FAIL';

export const PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS';

export const PROJECT_FETCH_START = 'PROJECT_FETCH_START';
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS';
export const PROJECT_FETCH_FAIL = 'PROJECT_FETCH_FAIL';

export const PROJECT_DELETE_START = 'PROJECT_DELETE_START';
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS';
export const PROJECT_DELETE_FAIL = 'PROJECT_DELETE_FAIL';

export const PROJECT_FINDBYID_START = 'PROJECT_FINDBYID_START';
export const PROJECT_FINDBYID_SUCCESS = 'PROJECT_FINDBYID_SUCCESS';
export const PROJECT_FINDBYID_FAIL = 'PROJECT_FINDBYID_FAIL';

// Visits
export const VISITORS_FETCH_START = 'VISITORS_FETCH_START';
export const VISITORS_FETCH_SUCCESS = 'VISITORS_FETCH_SUCCESS';
export const VISITORS_FETCH_FAIL = 'VISITORS_FETCH_FAIL';