import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';
import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import Spinner from '../../../components/UI/Spinner/Spinner';
import Input from '../../../components/UI/Input/Input';
import TransCap from '../../../components/TransCap/TransCap';

import { checkValid } from '../../../shared/utilities';

import classes from './ContactMe.module.css';

class ContactMe extends Component {

    state = {
        controls: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: counterpart('mainPages.contactMe.namePH'),
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                valueType: counterpart('mainPages.contactMe.name'),
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: counterpart('mainPages.contactMe.emailPH'),
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false,
                valueType: counterpart('mainPages.contactMe.email'),
            },
            subject: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: counterpart('mainPages.contactMe.subjectPH'),
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                valueType: counterpart('mainPages.contactMe.subject'),
            },
            message: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: counterpart('mainPages.contactMe.messagePH'),
                    rows: '9'
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                valueType: counterpart('mainPages.contactMe.message'),
            },
        },
        formIsValid: false,
        loadingFetched: false,
    };

    componentDidMount() {
        this.props.onInit();
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValid(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for (let inputIdent in updatedControls) {
            formIsValid = updatedControls[inputIdent].valid && formIsValid;
        }

        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    };

    submitHandler = event => {
        event.preventDefault();

        const formData = {};

        for (let formElementIdent in this.state.controls) {
            formData[formElementIdent] = this.state.controls[formElementIdent].value;
        }

        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date + ' ' + time;

        const data = {
            ...formData,
            datetime: dateTime
        }

        this.props.onSendMessage(data);
    };

    render() {

        const formElementsArray = [];

        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
            });
        }
        let form = formElementsArray.map(el => (
            <div className="form-group" key={el.id}>
                <label
                    style={{ textTransform: 'capitalize' }}>
                    {el.config.valueType}
                </label>
                <Input
                    elementType={el.config.elementType}
                    elementConfig={el.config.elementConfig}
                    value={el.config.value}
                    invalid={!el.config.valid}
                    shouldValidate={el.config.validation}
                    touched={el.config.touched}
                    valueType={el.config.valueType}
                    changed={(event) => this.inputChangedHandler(event, el.id)} />
            </div>
        ))

        let btnSend = (
            <button
                className="btn btn-dark"
                disabled={!this.state.formIsValid}>
                <i className="fa fa-paper-plane" aria-hidden="true"></i> <TransCap text='mainPages.contactMe.send' />
            </button>
        );

        if (this.props.loading) {
            btnSend = <Spinner size='20px' />
        }

        if (this.props.sent) {
            btnSend = null;
            form = (
                <h2 className="alert alert-success text-center">
                    <i className="fa fa-check-circle" aria-hidden="true"></i> <TransCap text='mainPages.contactMe.sent' />
                </h2>
            );
        }

        return (
            <div className={classes.ContactMe}>
                <h2 className="mb-5"> <i className="fa fa-envelope" aria-hidden="true"></i> <TransCap text='mainPages.contactMe.sendMeAMessage' /></h2>
                <div className={classes.Content}>
                    <form onSubmit={this.submitHandler}>
                        {form}

                        {btnSend}
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.messages.data,
        loading: state.messages.loading,
        error: state.messages.error,
        sent: state.messages.sent
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSendMessage: (data) => dispatch(actions.sendmessage(data)),
        onInit: () => dispatch(actions.messageSendInit())
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(ContactMe, axios));