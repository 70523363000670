export default {
    address: {
        address: 'address',
        street: 'street',
        city: 'city',
        country: 'country'
    },
    dateOfBirth: 'data of birth',
    maritalStatus: 'marital status',
    myLinkedInAccount: 'my LinkedIn account',
    myGitHubAccount: 'my GitHub account',
    download: 'download',
    cv: 'CV',
    mainNavigation: {
        home: 'home',
        education: 'education',
        experience: 'experience',
        skills: 'skills',
        trainingCourses: 'training courses',
        languages: 'languages',
        contactMe: 'contact me',
        projects: 'my projects',
    },
    mainPages: {
        home: {
            aboutMe: 'about me',
            aboutWebsite: 'about this website',
            developedBy: 'developed by me',
            techsUsed: 'Technologies used'
        },
        education: {
            myDegrees: 'my diplomas',
            years: 'years',
            mention: 'mention',
            moreDetails: 'more details',
            back: 'back'
        },
        experience: {
            myExperience: 'my experience',
            responisbilites: 'responisbilites',
            noExperiencesYet: 'no experiences yet'
        },
        projects: {
            myProjects: 'my projects',
            noProjectsYet: 'no projects yet',
            chooseProject: 'Please select a project.'
        },
        courses:{
            provider: 'provider',
            duration: 'duration',
            type: 'type',
            url: 'url'
        },
        languages: {
            read: 'read',
            write: 'write',
            speak: 'speak'
        },
        contactMe: {
            sendMeAMessage: 'send me a message',
            name: 'name',
            namePH: 'Please enter your name',
            email: 'email',
            emailPH: 'Please enter your email',
            subject: 'subject',
            subjectPH: 'Please enter message subject',
            message: 'message',
            messagePH: 'Please enter your message',
            send: 'send',
            sent: 'Your message was sent successfully. Thank You !'
        }
    },
     admin : {
        visits: {
            websiteVisits: 'visites du site',
            visitorsCount: 'nombre de visiteurs',
            totalVisitsCount: 'nombre total de visites',
            visitsCount: 'nombre de visites',
            totalVisits: 'total des visites'
        }
    },
    documents: 'documents',
    noDocumentsAvailable: 'no documents available',
    adminPages: {
        personalInfo:{
            personalInfo: 'personal Information'
        }
    },
    unavailable: 'unavailable',
    selectFile: 'Please select a file',
    downloadMyCV: 'Download my CV',
    close: 'Close',
    now: 'Now',
    projectType: 'Project type',
  
    date: 'Date',
    close: 'Close',
    details: 'Details',
    dateAndTime: 'Date & Time',
    error: 'Error'

}