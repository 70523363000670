import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';

export const projectAddInit = () => {
    return {
        type: actionTypes.PROJECT_ADD_INIT
    };
}
export const projectAddStart = _ => {
    return {
        type: actionTypes.PROJECT_ADD_START
    };
};

export const projectAddSuccess = (id, data) => {
    return {
        type: actionTypes.PROJECT_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const projectUpdateSuccess = (data) => {
    return {
        type: actionTypes.PROJECT_UPDATE_SUCCESS,
        data: data
    };
};


export const projectAddFail = error => {
    return {
        type: actionTypes.PROJECT_ADD_FAIL,
        error: error
    };
};


export const addProject = (data, token) => {
    return dispatch => {
        dispatch(projectAddStart());

        axios.post('/projects.json?auth=' + token, data)
            .then(response => {
                dispatch(projectAddSuccess(response.data.name, data));
            })
            .catch(error => {
                console.log(error);
                dispatch(projectAddFail(error));
            });
    };
};

export const updateProject = (data, token, prjId) => {
    return dispatch => {
        dispatch(projectAddStart());

        axios.patch('/projects/' + prjId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(projectUpdateSuccess(data));
            })
            .catch(error => {

                dispatch(projectAddFail(error));
            });

    };
};

// Fetch

export const projectFetchStart = _ => {
    return {
        type: actionTypes.PROJECT_FETCH_START
    };
};

export const projectFetchSuccess = (data) => {
    return {
        type: actionTypes.PROJECT_FETCH_SUCCESS,
        data: data
    };
};

export const projectFetchFail = error => {
    return {
        type: actionTypes.PROJECT_FETCH_FAIL,
        error: error
    };
};

const fetchProjectsHelper = dispatch => {
    axios.get('/projects.json')
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {
                fetchedData.push({
                    ...response.data[key],
                    id: key,
                });
            }

            dispatch(projectFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(projectFetchFail(error));
        });
};


export const fetchProjects = () => {
    return dispatch => {
        dispatch(projectFetchStart());

        fetchProjectsHelper(dispatch);
    };
};

/// Find By Id

export const projectFindByIdStart = () => {
    return {
        type: actionTypes.PROJECT_FINDBYID_START
    };
};

export const projectFindByIdSuccess = (project) => {
    return {
        type: actionTypes.PROJECT_FINDBYID_SUCCESS,
        project: project
    };
};

export const projectFindByIdFail = error => {
    return {
        type: actionTypes.PROJECT_FINDBYID_FAIL,
        error: error
    };
};

const projectFindByIdHelper = (dispatch, id) => {
    dispatch(projectFindByIdStart());

    axios.get('/projects/' + id + '.json')
        .then(response => {

            dispatch(projectFindByIdSuccess(response.data));
        })
        .catch(error => {
            dispatch(projectFindByIdFail(error));
        });
};


export const projectFindById = (id) => {
    return dispatch => {
        projectFindByIdHelper(dispatch, id);
    };
};


// Delete


export const projectDeleteStart = () => {
    return {
        type: actionTypes.PROJECT_DELETE_START
    };
};

export const projectDeleteSuccess = () => {
    return {
        type: actionTypes.PROJECT_DELETE_SUCCESS,
    };
};

export const projectDeleteFail = error => {
    return {
        type: actionTypes.PROJECT_DELETE_FAIL,
        error: error
    };
};


export const deleteProject = (id, token) => {
    return dispatch => {
        dispatch(projectDeleteStart());

        axios.delete('/projects/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(projectDeleteSuccess());
                fetchProjectsHelper(dispatch);

            })
            .catch(error => {
                dispatch(projectDeleteFail(error));
            });
    };
};
