import React from 'react';

import classes from './MainInfoLoader.module.css';

const mainInfoLoader = () => {
    return (
        <div className="row">
            <div className="col-lg-12 text-center">
                <div className={classes.Info}>
                    <div className="img-responsive text-center">
                        <div className={classes.ImgHolder}></div>
                    </div>
                    <h1> &nbsp; </h1>
                    <p>&nbsp;</p>
                    <div className={classes.InfoBox}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                    <div className={classes.InfoBox}>
                        <dl>
                            <dt>&nbsp;</dt>
                            <dd>&nbsp;</dd>
                            <dd>&nbsp;</dd>
                        </dl>
                    </div>
                    <div className={classes.InfoBox}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                    <p>
                        &nbsp;
                    </p>
                </div>
               
            </div>
        </div>
    );
}

export default mainInfoLoader;