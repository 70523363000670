import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


//// Update data

export const contactInfoUpdateStart = _ => {
    return {
        type: actionTypes.CONTACT_INFO_UPDATE_START
    };
};

export const contactInfoUpdateSuccess = ( data) => {
    return {
        type: actionTypes.CONTACT_INFO_UPDATE_SUCCESS,
        data: data
    };
};

export const contactInfoUpdateFail = error => {
    return {
        type: actionTypes.CONTACT_INFO_UPDATE_FAIL,
        error: error
    };
};

export const updateContactInfo = (data, token) => {
    return dispatch => {
        dispatch(contactInfoUpdateStart());

        axios.put('/contact-info.json?auth=' + token, data)
            .then(response => {
                dispatch(contactInfoUpdateSuccess(data));
            })
            .catch(error => {
                dispatch(contactInfoUpdateFail(error));
            });
    };
};


//// Fetch data

export const contactInfoFetchStart = _ => {
    return {
        type: actionTypes.CONTACT_INFO_FETCH_START
    };
};

export const contactInfoFetchSuccess = data => {
    return {
        type: actionTypes.CONTACT_INFO_FETCH_SUCCESS,
        data: data
    };
};

export const contactInfoFetchFail = error => {
    return {
        type: actionTypes.CONTACT_INFO_FETCH_FAIL,
        error: error
    };
};

export const fetchContactInfo = () => {
    return dispatch => {
        dispatch(contactInfoFetchStart());
        axios.get('/contact-info.json')
            .then(response => {
                dispatch(contactInfoFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(contactInfoFetchFail(error));
            });
    };
};