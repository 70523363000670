import React from 'react';

import TransCap from '../../../TransCap/TransCap';

import classes from '../Timeline.module.scss';

const timelineBlock = props => {

    let files = null;
    if (props.files.length > 0) {
        files = props.files.map(f => (
            <a
                key={f.id}
                href={f.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-dark btn-sm mt-2">
                <i className="fa fa-cloud-download" aria-hidden="true"></i> {f.title}
            </a>
        ))
    }


    return (
        <div className={classes["cd-timeline-block"]}>
            <div className={[classes["cd-timeline-img"], classes["cd-picture"]].join(' ')}>
            </div>

            <div className={classes["cd-timeline-content"]}>
                <h2>{props.degree}</h2>
                <div className={classes["timeline-content-info"]}>
                    <span className={classes["timeline-content-info-title"]}>
                        <i className="fa fa-certificate" aria-hidden="true"></i>
                        {props.major}
                    </span>
                    <span className={classes["timeline-content-info-date"]}>
                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                        {props.startYear} - {props.finishYear !== 0 ? props.finishYear : 'Actuellement'}
                    </span>
                </div>
                {/* <p>Working alongside the designer team implementing the designs, also developing custom solutions to address team necessities.</p> */}
                <ul className={classes["content-skills"]}>
                    <li><TransCap text="mainPages.education.years" />: {props.years}</li>
                    <li><TransCap text="mainPages.education.mention" />: {props.mention}</li>
                    <li> {props.university}</li>
                    <li><TransCap text="address.city" />: {props.city}</li>
                    <li><TransCap text="address.country" />: {props.country}</li>
                </ul>
                {files.length > 0 ? <p><TransCap text="documents" /></p> : null}
                {files}
                {/* <Link
                    to={'/education/details/' + props.id}
                    className="btn btn-dark btn-sm mt-2">
                    <i className="fa fa-eye" aria-hidden="true"></i> <TransCap text="mainPages.education.moreDetails" />
                </Link> */}
            </div>
        </div>
    );
};

export default timelineBlock;