import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

export const experienceAddInit = () => {
    return {
        type: actionTypes.EXPERIENCE_ADD_INIT
    };
}
export const experienceAddStart = _ => {
    return {
        type: actionTypes.EXPERIENCE_ADD_START
    };
};

export const experienceAddSuccess = (id, data) => {
    return {
        type: actionTypes.EXPERIENCE_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const experienceUpdateSuccess = (data) => {
    return {
        type: actionTypes.EXPERIENCE_UPDATE_SUCCESS,
        data: data
    };
};


export const experienceAddFail = error => {
    return {
        type: actionTypes.EXPERIENCE_ADD_FAIL,
        error: error
    };
};


export const addExperience = (data, token) => {
    return dispatch => {
        dispatch(experienceAddStart());

        axios.post('/experiences.json?auth=' + token, data)
            .then(response => {
                dispatch(experienceAddSuccess(response.data.name, data));
            })
            .catch(error => {
                console.log(error);
                dispatch(experienceAddFail(error));
            });
    };
};

export const updateExperience = (data, token, expId) => {
    return dispatch => {
        dispatch(experienceAddStart());

        axios.patch('/experiences/' + expId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(experienceUpdateSuccess(data));
            })
            .catch(error => {

                dispatch(experienceAddFail(error));
            });

    };
};

// Fetch

export const experienceFetchStart = _ => {
    return {
        type: actionTypes.EXPERIENCE_FETCH_START
    };
};

export const experienceFetchSuccess = (data) => {
    return {
        type: actionTypes.EXPERIENCE_FETCH_SUCCESS,
        data: data
    };
};

export const experienceFetchFail = error => {
    return {
        type: actionTypes.EXPERIENCE_FETCH_FAIL,
        error: error
    };
};

const fetchExperiencesHelper = dispatch => {
    axios.get('/experiences.json')
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {
                fetchedData.push({
                    ...response.data[key],
                    id: key,
                });
            }

            fetchedData.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            dispatch(experienceFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(experienceFetchFail(error));
        });
};


export const fetchExperiences = () => {
    return dispatch => {
        dispatch(experienceFetchStart());

        fetchExperiencesHelper(dispatch);
    };
};

/// Find By Id

export const experienceFindByIdStart = () => {
    return {
        type: actionTypes.EXPERIENCE_FINDBYID_START
    };
};

export const experienceFindByIdSuccess = (data) => {
    return {
        type: actionTypes.EXPERIENCE_FINDBYID_SUCCESS,
        data: data
    };
};

export const experienceFindByIdFail = error => {
    return {
        type: actionTypes.EXPERIENCE_FINDBYID_FAIL,
        error: error
    };
};

const experienceFindByIdHelper = (dispatch, id) => {
    dispatch(experienceFindByIdStart());

    axios.get('/experiences/' + id + '.json')
        .then(response => {

            const fetchedData = [];

            fetchedData.push({
                ...response.data,
                id: id,
            });

            dispatch(experienceFindByIdSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(experienceFindByIdFail(error));
        });
};


export const experienceFindById = (id) => {
    return dispatch => {
        experienceFindByIdHelper(dispatch,id);
    };
};


// Delete


export const experienceDeleteStart = () => {
    return {
        type: actionTypes.EXPERIENCE_DELETE_START
    };
};

export const experienceDeleteSuccess = () => {
    return {
        type: actionTypes.EXPERIENCE_DELETE_SUCCESS,
    };
};

export const experienceDeleteFail = error => {
    return {
        type: actionTypes.EXPERIENCE_DELETE_FAIL,
        error: error
    };
};


export const deleteExperience = (id, token) => {
    return dispatch => {
        dispatch(experienceDeleteStart());

        axios.delete('/experiences/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(experienceDeleteSuccess());
                fetchExperiencesHelper(dispatch);

            })
            .catch(error => {
                dispatch(experienceDeleteFail(error));
            });
    };
};

// Upload File


export const uploadFileStart = () => {
    return {
        type: actionTypes.EXPERIENCE_UPLOADFILE_START
    };
};

export const uploadFileProgress = progress => {
    return {
        type: actionTypes.EXPERIENCE_UPLOADFILE_PROGRESS,
        progress: progress
    };
};

export const uploadFileSuccess = (id) => {
    return {
        type: actionTypes.EXPERIENCE_UPLOADFILE_SUCCESS,
        id: id
    };
};

export const uploadFileFail = error => {
    return {
        type: actionTypes.EXPERIENCE_UPLOADFILE_FAIL,
        error: error
    };
};

export const experienceUploadFile = (data, token, cfile) => {
    return dispatch => {
        dispatch(uploadFileStart());

        const file = cfile;
        const fname = new Date().getTime() + '_' + file.name;

        const uploadTask = storage.ref(`experiences/${fname}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                dispatch(uploadFileProgress(progress));
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(uploadFileFail(error));
            },
            () => {
                // complete function ....
                storage.ref('experiences').child(fname).getDownloadURL().then(url => {

                    const dataWithFile = {
                        ...data,
                        fileUrl: url,
                        fileName: fname,
                        fileSize: file.size,
                        fileType: file.type
                    }

                    axios.post('/experiences/' + data.expId + '/files.json?auth=' + token, dataWithFile)
                        .then(response => {
                            dispatch(uploadFileSuccess(response.data.name));
                            experienceFindByIdHelper(dispatch, data.expId);
                        })
                        .catch(error => {
                            dispatch(uploadFileFail(error));
                        });
                })
            });
    };
};


// Delete file

export const deleteFileStart = () => {
    return {
        type: actionTypes.EXPERIENCE_DELETE_FILE_START
    }
};

export const deleteFileSuccess = () => {
    return {
        type: actionTypes.EXPERIENCE_DELETE_FILE_SUCCESS
    }
};

export const deleteFileFail = error => {
    return {
        type: actionTypes.EXPERIENCE_DELETE_FILE_FAIL,
        errorDeleteFile: error
    }
};

export const experienceDeleteFile = (id, fileName, expId, token) => {
    return dispatch => {
        dispatch(deleteFileStart());
        const fileRef = storage.ref().child('experiences/' + fileName);

        fileRef.delete().then(() => {
            axios.delete('/experiences/' + expId + '/files/' + id + '.json?auth=' + token)
                .then(response => {
                    dispatch(deleteFileSuccess());
                    experienceFindByIdHelper(dispatch, expId);
                })
                .catch(error => {
                    dispatch(deleteFileFail(error));
                });
        }).catch(error => {
            dispatch(deleteFileFail());
        });
    }
};