import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';

import * as actions from '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler';

import Spinner from '../../../components/UI/Spinner/Spinner';

import Course from '../../../components/Main/Courses/Course';

import classes from './Courses.module.css';

import TransCap from '../../../components/TransCap/TransCap';

class Courses extends Component {
    state = {
        courses: []
    };

    componentDidMount() {
        this.props.onFetchCourses();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const st = {
            ...prevState,
            courses: nextProps.data
        };
        return st;
    }

    render() {
        const lang = counterpart.getLocale();

        let courses = <Spinner size='100px' />

        if (!this.props.loading) {
            courses = this.state.courses.map(c => {

                const files = [];
                for (let f in c.files) {
                    files.push({
                        ...c.files[f],
                        id: f
                    });
                }

                return <Course
                    key={c.id}
                    title={c.title}
                    duration={c.duration}
                    provider={c.provider}
                    type={lang === 'fr' ? c.type_FR : c.type_EN}
                    url={c.url}
                    city={lang === 'fr' ? c.city_FR : c.city_EN}
                    country={lang === 'fr' ? c.country_FR : c.country_EN}
                    docs={files} />
            })
        }

        return (
            <div className={classes.Courses}>
                <h2 className="mb-5"> <i className="fa fa-leanpub" aria-hidden="true"></i> <TransCap text="mainNavigation.trainingCourses" /></h2>
                {courses}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.cvCourses.data,
        loading: state.cvCourses.loading,
        error: state.cvCourses.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCourses: () => dispatch(actions.fetchCourses()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Courses, axios));