import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-firebase';
import counterpart from 'counterpart';

import MainInfoLoader from '../../../components/UI/MainInfoLoader/MainInfoLoader';

import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import MainInfoContent from '../../../components/Main/MainInfo/MainInfo';

import classes from './MainInfo.module.css';

class MainInfo extends Component {

    state = {
        mainInfo: {},
        cvFiles: [],
        loading: false,
        modal: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        const files = [];
        for (let key in nextProps.data['cv-files']) {
            files.push({
                ...nextProps.data['cv-files'][key],
                id: key
            });
        }

        const st = {
            ...prevState,
            mainInfo: nextProps.data,
            cvFiles: [...files],
            loading: nextProps.loading
        };

        return st;
    }

    componentDidMount() {
        this.props.onFetch();
    }

 
    render() {
        const lang = counterpart.getLocale();
        let main = <MainInfoLoader />;

        if (!this.state.loading) {
            main = (
                <div className={classes.MainInfoContent}>
                    <MainInfoContent 
                        imageUrl={this.state.mainInfo.imageUrl}
                        firstName={this.state.mainInfo.firstName}
                        lastName={this.state.mainInfo.lastName}
                        title={lang === 'fr' ? this.state.mainInfo.title_FR : this.state.mainInfo.title_EN}
                        email={this.state.mainInfo.email}
                        phone={this.state.mainInfo.phone}
                        street={this.state.mainInfo.street}
                        zipCode={this.state.mainInfo.zipCode}
                        city={this.state.mainInfo.city}
                        country={this.state.mainInfo.country}
                        dateOfBirth={this.state.mainInfo.dateOfBirth}
                        maritalStatus={lang === 'fr' ? this.state.mainInfo.maritalStatus_FR : this.state.mainInfo.maritalStatus_EN}
                        linkedin={this.state.mainInfo.linkedin}
                        github={this.state.mainInfo.github}
                        facebook={this.state.mainInfo.facebook}
                        modalOpen={this.props.openModalHandler}
                        lang={this.props.lang} 
                        setLangEN={this.props.setLangEN}
                        setLangFR={this.props.setLangFR} 
                    />
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className={classes.MainInfo}>
                    <div className="container-fluid">
                        
                        {main}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.mainInfo.data,
        loading: state.mainInfo.loading,
        error: state.mainInfo.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetch: () => dispatch(actions.fetchMainInfo())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(MainInfo, axios));
