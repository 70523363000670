import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    data: {},
    loading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.MAIN_INFO_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.MAIN_INFO_FETCH_SUCCESS:
            const fetchedData = updateObject(state.data, action.data);

            return updateObject(state, { loading: false, data: fetchedData });

        case actionTypes.MAIN_INFO_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });

        default:
            return state;
    }


};


export default reducer;
