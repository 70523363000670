import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

export const courseAddInit = () => {
    return {
        type: actionTypes.COURSE_ADD_INIT
    };
}
export const courseAddStart = _ => {
    return {
        type: actionTypes.COURSE_ADD_START
    };
};

export const courseAddSuccess = (id, data) => {
    return {
        type: actionTypes.COURSE_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const courseUpdateSuccess = (data) => {
    return {
        type: actionTypes.COURSE_UPDATE_SUCCESS,
        data: data
    };
};


export const courseAddFail = error => {
    return {
        type: actionTypes.COURSE_ADD_FAIL,
        error: error
    };
};


export const addCourse = (data, token) => {
    return dispatch => {
        dispatch(courseAddStart());

        axios.post('/courses.json?auth=' + token, data)
            .then(response => {
                dispatch(courseAddSuccess(response.data.name, data));
            })
            .catch(error => {
                console.log(error);
                dispatch(courseAddFail(error));
            });
    };
};

export const updateCourse = (data, token, crsId) => {
    return dispatch => {
        dispatch(courseAddStart());

        axios.patch('/courses/' + crsId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(courseUpdateSuccess(data));
            })
            .catch(error => {

                dispatch(courseAddFail(error));
            });
    };
};

// Fetch

export const courseFetchStart = _ => {
    return {
        type: actionTypes.COURSE_FETCH_START
    };
};

export const courseFetchSuccess = (data) => {
    return {
        type: actionTypes.COURSE_FETCH_SUCCESS,
        data: data
    };
};

export const courseFetchFail = error => {
    return {
        type: actionTypes.COURSE_FETCH_FAIL,
        error: error
    };
};

const fetchCoursesHelper = dispatch => {
    axios.get('/courses.json')
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {
                fetchedData.push({
                    ...response.data[key],
                    id: key,
                });
            }

            dispatch(courseFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(courseFetchFail(error));
        });
};


export const fetchCourses = () => {
    return dispatch => {
        dispatch(courseFetchStart());

        fetchCoursesHelper(dispatch);
    };
};

/// Find By Id

export const courseFindByIdStart = () => {
    return {
        type: actionTypes.COURSE_FINDBYID_START
    };
};

export const courseFindByIdSuccess = (data) => {
    return {
        type: actionTypes.COURSE_FINDBYID_SUCCESS,
        data: data
    };
};

export const courseFindByIdFail = error => {
    return {
        type: actionTypes.COURSE_FINDBYID_FAIL,
        error: error
    };
};


const courseFindByIdHelper = (dispatch, id) => {
    dispatch(courseFindByIdStart());

    axios.get('/courses/' + id + '.json')
        .then(response => {

            const fetchedData = [];

            fetchedData.push({
                ...response.data,
                id: id,
            });

            dispatch(courseFindByIdSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(courseFindByIdFail(error));
        });
};

export const courseFindById = (id) => {
    return dispatch => {
        courseFindByIdHelper(dispatch, id);
    };
};


// Delete


export const courseDeleteStart = () => {
    return {
        type: actionTypes.COURSE_DELETE_START
    };
};

export const courseDeleteSuccess = () => {
    return {
        type: actionTypes.COURSE_DELETE_SUCCESS,
    };
};

export const courseDeleteFail = error => {
    return {
        type: actionTypes.COURSE_DELETE_FAIL,
        error: error
    };
};


export const deleteCourse = (id, token) => {
    return dispatch => {
        dispatch(courseDeleteStart());

        axios.delete('/courses/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(courseDeleteSuccess());
                fetchCoursesHelper(dispatch);

            })
            .catch(error => {
                dispatch(courseDeleteFail(error));
            });
    };
};


// Upload File


export const uploadFileStart = () => {
    return {
        type: actionTypes.COURSE_UPLOADFILE_START
    };
};

export const uploadFileProgress = progress => {
    return {
        type: actionTypes.COURSE_UPLOADFILE_PROGRESS,
        progress: progress
    };
};

export const uploadFileSuccess = (id) => {
    return {
        type: actionTypes.COURSE_UPLOADFILE_SUCCESS,
        id: id
    };
};

export const uploadFileFail = error => {
    return {
        type: actionTypes.COURSE_UPLOADFILE_FAIL,
        error: error
    };
};

export const courseUploadFile = (data, token, cfile) => {
    return dispatch => {
        dispatch(uploadFileStart());

        const file = cfile;
        const fname = new Date().getTime() + '_' + file.name;

        const uploadTask = storage.ref(`courses/${fname}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                dispatch(uploadFileProgress(progress));
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(uploadFileFail(error));
            },
            () => {
                // complete function ....
                storage.ref('courses').child(fname).getDownloadURL().then(url => {

                    const dataWithFile = {
                        ...data,
                        fileUrl: url,
                        fileName: fname,
                        fileSize: file.size,
                        fileType: file.type
                    }

                    axios.post('/courses/' + data.courseId + '/files.json?auth=' + token, dataWithFile)
                        .then(response => {
                            dispatch(uploadFileSuccess(response.data.name));
                            courseFindByIdHelper(dispatch, data.courseId);
                        })
                        .catch(error => {
                            dispatch(uploadFileFail(error));
                        });
                })
            });
    };
};

// Delete file


export const deleteFileA = () => {
    return {
        type: actionTypes.COURSE_DELETE_FILE
    }
};

export const deleteFileFail = () => {
    return {
        type: actionTypes.COURSE_DELETE_FILE_FAIL
    }
};

export const courseDeleteFile = (id, fileName, crsId, token) => {
    return dispatch => {

        const fileRef = storage.ref().child('courses/' + fileName);

        fileRef.delete().then(() => {
            axios.delete('/courses/' + crsId + '/files/' + id + '.json?auth=' + token)
                .then(response => {
                    dispatch(deleteFileA());
                    courseFindByIdHelper(dispatch, crsId);
                })
                .catch(error => {
                    dispatch(deleteFileFail());
                });
        }).catch(error => {
            dispatch(deleteFileFail());
        });
    }
};