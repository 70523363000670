import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';
import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import Spinner from '../../../components/UI/Spinner/Spinner';

import Timeline from '../../../components/Main/Timeline/Timeline';
import TimelineBlock from '../../../components/Main/Timeline/Education/TimelineEducationBlock';

import TransCap from '../../../components/TransCap/TransCap';

import classes from './Education.module.css';

class Education extends Component {

    componentDidMount() {
        this.props.onFetchEducations();
    }


    render() {
        const lang = counterpart.getLocale();

        let educationsRender = <Spinner size="100px" />;

        if (!this.props.loading) {
            const educations = this.props.data.map(e => {
                const files = [];
                for (let f in e.files) {
                    files.push({
                        ...e.files[f],
                        id: f
                    });
                }

                return <TimelineBlock
                    key={e.id}
                    id={e.id}
                    degree={lang === 'fr' ? e.degree_FR : e.degree_EN}
                    major={lang === 'fr' ? e.major_FR : e.major_EN}
                    years={e.years}
                    startYear={e.startYear}
                    finishYear={e.finishYear}
                    university={lang === 'fr' ? e.university_FR : e.university_EN}
                    city={lang === 'fr' ? e.city_FR : e.city_EN}
                    country={lang === 'fr' ? e.country_FR : e.country_EN}
                    mention={lang === 'fr' ? e.mention_FR : e.mention_EN}
                    files={files}
                />
            });

            educationsRender = (
                <div>
                    <h2 style={{ color: 'lightgrey' }}>
                        <i className="fa fa-graduation-cap" aria-hidden="true"></i> <TransCap text="mainPages.education.myDegrees" />
                    </h2>

                    <div className={classes.Timeline}>
                        <Timeline >
                            {educations}
                        </Timeline>
                    </div>
                </div>
            );

        }

        return (
            <div className={classes.Education}>
                {educationsRender}
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        data: state.cvEducations.data,
        loading: state.cvEducations.loading,
        error: state.cvEducations.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchEducations: () => dispatch(actions.fetchEducations()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Education, axios));