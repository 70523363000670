import React from 'react';

import classes from './Timeline.module.scss';


const timeline = props => {
    return (
        <section className={[classes["cd-container"], classes["cd-timeline"]].join(' ')}>

            {props.children}
           
        </section>
    );
}

export default timeline;