import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';

import * as actions from '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler';

import Spinner from '../../../components/UI/Spinner/Spinner';

import classes from './Languages.module.css';

import LanguageChart from '../../../components/Main/Languages/LanguageChart';

import TransCap from '../../../components/TransCap/TransCap';

class Languages extends Component {
    state = {
        languages: []
    };

    componentDidMount() {
        this.props.onFetchLanguages();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const st = {
            ...prevState,
            languages: nextProps.data
        };
        return st;
    }

    render() {
        const lang = counterpart.getLocale();
        let languages = <Spinner size='100px' />


        if (!this.props.loading) {
            languages = this.state.languages.map(l => {

                const files = [];
                for (let f in l.files) {
                    files.push({
                        ...l.files[f],
                        id: f
                    });
                }

                return <LanguageChart
                    key={l.id}
                    name={lang === 'fr' ? l.name_FR : l.name_EN}
                    read={l.read}
                    write={l.write}
                    speak={l.speak}
                    docs={files} />
            })
        }


        return (
            <div className={classes.Languages}>
                <h2 className="mb-5"> <i className="fa fa-globe" aria-hidden="true"></i> <TransCap text="mainNavigation.languages" /></h2>

                {languages}

              
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.cvLanguages.data,
        loading: state.cvLanguages.loading,
        error: state.cvLanguages.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLanguages: () => dispatch(actions.fetchLanguages()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Languages, axios));