import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

export const educationAddInit = () => {
    return {
        type: actionTypes.EDUCATION_ADD_INIT
    };
}
export const educationAddStart = () => {
    return {
        type: actionTypes.EDUCATION_ADD_START
    };
};

export const educationAddSuccess = (id, data) => {
    return {
        type: actionTypes.EDUCATION_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const educationUpdateSuccess = data => {
    return {
        type: actionTypes.EDUCATION_UPDATE_SUCCESS,
        data: data
    };
};


export const educationAddFail = error => {
    return {
        type: actionTypes.EDUCATION_ADD_FAIL,
        error: error
    };
};


export const addEducation = (data, token) => {
    return dispatch => {
        dispatch(educationAddStart());

        axios.post('/educations.json?auth=' + token, data)
            .then(response => {
                dispatch(educationAddSuccess(response.data.name, data));
            })
            .catch(error => {
                console.log(error);
                dispatch(educationAddFail(error));
            });
    };
};

export const updateEducation = (data, token, eduId) => {
    return dispatch => {
        dispatch(educationAddStart());

        axios.patch('/educations/' + eduId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(educationUpdateSuccess(data));
            })
            .catch(error => {

                dispatch(educationAddFail(error));
            });

    };
};

// Fetch

export const educationFetchStart = () => {
    return {
        type: actionTypes.EDUCATION_FETCH_START
    };
};

export const educationFetchSuccess = (data) => {
    return {
        type: actionTypes.EDUCATION_FETCH_SUCCESS,
        data: data
    };
};

export const educationFetchFail = error => {
    return {
        type: actionTypes.EDUCATION_FETCH_FAIL,
        error: error
    };
};

const fetchEducationsHelper = (dispatch) => {
    axios.get('/educations.json')
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {
                fetchedData.push({
                    ...response.data[key],
                    id: key,
                });
            }
            fetchedData.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            dispatch(educationFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(educationFetchFail(error));
        });
};


export const fetchEducations = () => {
    return dispatch => {
        dispatch(educationFetchStart());

        fetchEducationsHelper(dispatch);
    };
};

/// Find By Id

export const educationFindByIdStart = () => {
    return {
        type: actionTypes.EDUCATION_FINDBYID_START
    };
};

export const educationFindByIdSuccess = (data) => {
    return {
        type: actionTypes.EDUCATION_FINDBYID_SUCCESS,
        data: data
    };
};

export const educationFindByIdFail = error => {
    return {
        type: actionTypes.EDUCATION_FINDBYID_FAIL,
        error: error
    };
};


const educationFindByIdHelper = (dispatch, id) => {
    dispatch(educationFindByIdStart());

    axios.get('/educations/' + id + '.json')
        .then(response => {

            const fetchedData = [];

            fetchedData.push({
                ...response.data,
                id: id,
            });

            dispatch(educationFindByIdSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(educationFindByIdFail(error));
        });
};

export const educationFindById = (id) => {
    return dispatch => {
        educationFindByIdHelper(dispatch, id);
    };
};


// Delete


export const educationDeleteStart = () => {
    return {
        type: actionTypes.EDUCATION_DELETE_START
    };
};

export const educationDeleteSuccess = () => {
    return {
        type: actionTypes.EDUCATION_DELETE_SUCCESS,
    };
};

export const educationDeleteFail = error => {
    return {
        type: actionTypes.EDUCATION_DELETE_FAIL,
        error: error
    };
};


export const deleteEducation = (id, token) => {
    return dispatch => {
        dispatch(educationDeleteStart());

        axios.delete('/educations/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(educationDeleteSuccess());
                fetchEducationsHelper(dispatch);

            })
            .catch(error => {
                dispatch(educationDeleteFail(error));
            });
    };
};

// Upload File


export const uploadFileStart = () => {
    return {
        type: actionTypes.EDUCATION_UPLOADFILE_START
    };
};

export const uploadFileProgress = progress => {
    return {
        type: actionTypes.EDUCATION_UPLOADFILE_PROGRESS,
        progress: progress
    };
};

export const uploadFileSuccess = (id) => {
    return {
        type: actionTypes.EDUCATION_UPLOADFILE_SUCCESS,
        id: id
    };
};

export const uploadFileFail = error => {
    return {
        type: actionTypes.EDUCATION_UPLOADFILE_FAIL,
        error: error
    };
};

export const educationUploadFile = (data, token, cfile) => {
    return dispatch => {
        dispatch(uploadFileStart());

        const file = cfile;
        const fname = new Date().getTime() + '_' + file.name;

        const uploadTask = storage.ref(`educations/${fname}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                dispatch(uploadFileProgress(progress));
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(uploadFileFail(error));
            },
            () => {
                // complete function ....
                storage.ref('educations').child(fname).getDownloadURL().then(url => {

                    const dataWithFile = {
                        ...data,
                        fileUrl: url,
                        fileName: fname,
                        fileSize: file.size,
                        fileType: file.type
                    }

                    axios.post('/educations/' + data.eduId + '/files.json?auth=' + token, dataWithFile)
                        .then(response => {
                            dispatch(uploadFileSuccess(response.data.name));
                            educationFindByIdHelper(dispatch, data.eduId);
                        })
                        .catch(error => {
                            dispatch(uploadFileFail(error));
                        });
                })
            });
    };
};


// Delete file

export const deleteFileStart = () => {
    return {
        type: actionTypes.EDUCATION_DELETE_FILE_START
    }
};

export const deleteFileSuccess = () => {
    return {
        type: actionTypes.EDUCATION_DELETE_FILE_SUCCESS
    }
};

export const deleteFileFail = error => {
    return {
        type: actionTypes.EDUCATION_DELETE_FILE_FAIL,
        errorDeleteFile: error
    }
};

export const educationDeleteFile = (id, fileName, eduId, token) => {
    return dispatch => {
        dispatch(deleteFileStart());
        const fileRef = storage.ref().child('educations/' + fileName);

        fileRef.delete().then(() => {
            axios.delete('/educations/' + eduId + '/files/' + id + '.json?auth=' + token)
                .then(response => {
                    dispatch(deleteFileSuccess());
                    educationFindByIdHelper(dispatch, eduId);
                })
                .catch(error => {
                    dispatch(deleteFileFail(error));
                });
        }).catch(error => {
            dispatch(deleteFileFail());
        });
    }
};