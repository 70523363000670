import React from 'react';

import CircleChart from '../../UI/CircleChart/CircleChart';

import classes from './Skill.module.css';

const skill = props => {

    return (
        <div className={classes.Skill}>
            <div className={classes.Chart}>
                <CircleChart text={props.level + '%'} value={props.level} size="small" />
            </div>
            <div className="clearfix"></div>
            <h6>{props.name}</h6>

        </div>
    );
}

export default skill;