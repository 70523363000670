import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';
import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import Spinner from '../../../components/UI/Spinner/Spinner';

import Timeline from '../../../components/Main/Timeline/Timeline';
import TimelineBlock from '../../../components/Main/Timeline/Experience/TimelineExperienceBlock';

import classes from './Experience.module.css';

import TransCap from '../../../components/TransCap/TransCap';

class Experience extends Component {

    componentDidMount() {
        this.props.onFetchExperiences();
    }

    render() {
        const lang = counterpart.getLocale();

        let experiencesRender = <Spinner size="100px" />;

        if (!this.props.loading) {
            const experiences = this.props.data.map(e => {
                const files = [];
                for (let f in e.files) {
                    files.push({
                        ...e.files[f],
                        id: f
                    });
                }

                return <TimelineBlock
                    key={e.id}
                    id={e.id}
                    post={lang === 'fr' ? e.post_FR : e.post_EN}
                    company={e.company}
                    companyWebsite={e.companyWebsite}
                    startDate={e.startDate}
                    finishDate={e.finishDate}
                    city={lang === 'fr' ? e.city_FR : e.city_EN}
                    country={lang === 'fr' ? e.country_FR : e.country_EN}
                    responsibilites={lang === 'fr' ? e.responsibilites_FR : e.responsibilites_EN}
                    files={files}
                />
            });

            experiencesRender = (
                <div>
                    <h2 style={{ color: 'lightgrey' }}> <i className="fa fa-star" aria-hidden="true"></i> <TransCap text="mainPages.experience.myExperience" /></h2>

                    <div className={classes.Timeline}>
                        <Timeline >
                            {
                            experiences.length > 0
                            ? experiences
                            : <h3 className="alert alert-info text-center"><TransCap text="mainPages.experience.noExperiencesYet" /></h3>
                            }
                        </Timeline>
                    </div>
                </div>
            );

        }

        return (
            <div className={classes.Experience}>
                {experiencesRender}
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        data: state.cvExperiences.data,
        loading: state.cvExperiences.loading,
        error: state.cvExperiences.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchExperiences: () => dispatch(actions.fetchExperiences()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Experience, axios));