import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from '../../../../axios-firebase';
import * as actions from '../../../../store/actions/index';
import withErrorHandler from '../../../../hoc/withErrorHandler';

import Spinner from '../../../../components/UI/Spinner/Spinner';

import classes from './EducationDetails.module.css';

import TransCap from '../../../../components/TransCap/TransCap';

class EducationDetails extends Component {

    state = {
        education: {
            id: '',
            degree: '',
            mention: '',
            startYear: 0,
            finishYear: 0,
            years: 0,
            major: '',
            city: '',
            country: '',
            university: '',
            files: null
        }
    };

    componentDidMount() {
        const id = this.props.match.params.id;

        this.props.onFindById(id);
    }



    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {
            ...prevState,
            education: {
                ...nextProps.data
            }
        };

        return newState;
    }

    render() {

        let details = <Spinner size='100px' />

        const files = [];
        for (let f in this.state.education.files) {
            files.push({
                ...this.state.education.files[f],
                id: f
            });
        }

        if (!this.props.loading) {
            details = (
                <React.Fragment>
                    <div className="row">
                        <div className="col-lg-10">
                            <h1> <i className="fa fa-graduation-cap" aria-hidden="true"></i> {this.state.education.degree}</h1>
                        </div>
                        <div className="col-lg-2">
                            <Link
                                to="/education"
                                className="btn btn-dark btn-block">
                                <TransCap text="mainPages.education.back" />
                            </Link>
                        </div>
                    </div>

                    <div className={classes.Content}>
                        <div className="row mt-5">
                            <div className="col-lg-6">

                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-star" aria-hidden="true"></i> {this.state.education.major}
                                </div>
                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-tag" aria-hidden="true"></i> {this.state.education.mention}
                                </div>
                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-university" aria-hidden="true"></i> {this.state.education.university}
                                </div>
                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-clock-o" aria-hidden="true"></i> {this.state.education.years}
                                </div>
                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-play-circle" aria-hidden="true"></i> {this.state.education.startYear}
                                </div>
                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-stop-circle" aria-hidden="true"></i> {this.state.education.finishYear}
                                </div>

                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {this.state.education.city}
                                </div>
                                <div className={classes.DetailsBox}>
                                    <i className="fa fa-globe" aria-hidden="true"></i> {this.state.education.country}
                                </div>
                            </div>
                               
                            <div className="col-lg-6">
                                <hr className={classes.Line} />
                                <h2><TransCap text="documents" /></h2>
                                {

                                    files.length > 0
                                        ? files.map(f => (
                                            <a href={f.fileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-primary">
                                                <i className="fa fa-cloud-download" aria-hidden="true"></i> {f.title}
                                            </a>
                                        ))
                                        : <p><TransCap  text="noDocumentsAvailable"/></p>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div className={classes.EducationDetails}>
                {details}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.cvEducations.data[0],
        loading: state.cvEducations.loading,
        error: state.cvEducations.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFindById: (id) => dispatch(actions.educationFindById(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(EducationDetails, axios));