import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';

export const skillAddInit = () => {
    return {
        type: actionTypes.SKILL_ADD_INIT
    };
}
export const skillAddStart = _ => {
    return {
        type: actionTypes.SKILL_ADD_START
    };
};

export const skillAddSuccess = (id, data) => {
    return {
        type: actionTypes.SKILL_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const skillUpdateSuccess = (data) => {
    return {
        type: actionTypes.SKILL_UPDATE_SUCCESS,
        data: data
    };
};


export const skillAddFail = error => {
    return {
        type: actionTypes.SKILL_ADD_FAIL,
        error: error
    };
};


export const addSkill = (data, token) => {
    return dispatch => {
        dispatch(skillAddStart());

        axios.post('/skills/' + data.category + '.json?auth=' + token, data)
            .then(response => {
                dispatch(skillAddSuccess(response.data.name, data));
            })
            .catch(error => {
                console.log(error);
                dispatch(skillAddFail(error));
            });
    };
};

// export const addSkill = (data, token) => {
//     return dispatch => {
//         dispatch(skillAddStart());

//         axios.post('/skills.json?auth=' + token, data)
//             .then(response => {
//                 dispatch(skillAddSuccess(response.data.name, data));
//             })
//             .catch(error => {
//                 console.log(error);
//                 dispatch(skillAddFail(error));
//             });
//     };
// };


export const updateSkill = (data, token, skId, curCat) => {
    return dispatch => {
        dispatch(skillAddStart());

        if (data.category === curCat) {
            axios.patch('/skills/' + data.category + '/' + skId + '.json?auth=' + token, data)
                .then(response => {
                    dispatch(skillUpdateSuccess(data));
                })
                .catch(error => {
                    dispatch(skillAddFail(error));
                });
        } else {
            axios.post('/skills/' + data.category + '.json?auth=' + token, data)
                .then(response => {
                    deleteSkillHelper(dispatch, skId, curCat);
                    dispatch(skillUpdateSuccess(data));
                })
                .catch(error => {
                    dispatch(skillAddFail(error));
                });
        }


    };
};

// Fetch

export const skillFetchStart = _ => {
    return {
        type: actionTypes.SKILL_FETCH_START
    };
};

export const skillFetchSuccess = (data) => {
    return {
        type: actionTypes.SKILL_FETCH_SUCCESS,
        data: data
    };
};

export const skillFetchFail = error => {
    return {
        type: actionTypes.SKILL_FETCH_FAIL,
        error: error
    };
};

const fetchSkillsHelper = dispatch => {
    axios.get('/skills.json')
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {

                let obj = {
                    id: key,
                };
                let items = [];

                for (let innerKey in response.data[key]) {
                    items = [
                        ...items,
                        { ...response.data[key][innerKey], id: innerKey }
                    ]
                    obj = {
                        ...obj,
                        items: [
                            ...items,
                        ]
                    }
                }

                fetchedData.push(obj);
            }
            dispatch(skillFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(skillFetchFail(error));
        });
};


export const fetchSkills = () => {
    return dispatch => {
        dispatch(skillFetchStart());

        fetchSkillsHelper(dispatch);
    };
};

/// Find By Id

export const skillFindByIdStart = () => {
    return {
        type: actionTypes.SKILL_FINDBYID_START
    };
};

export const skillFindByIdSuccess = (data) => {
    return {
        type: actionTypes.SKILL_FINDBYID_SUCCESS,
        data: data
    };
};

export const skillFindByIdFail = error => {
    return {
        type: actionTypes.SKILL_FINDBYID_FAIL,
        error: error
    };
};


// export const skillFindById = (id) => {
//     return dispatch => {
//         dispatch(skillFindByIdStart());

//         axios.get('/skills/' + id + '.json')
//             .then(response => {

//                 const fetchedData = [];

//                 fetchedData.push({
//                     ...response.data,
//                     id: id,
//                 });

//                 dispatch(skillFindByIdSuccess(fetchedData));
//             })
//             .catch(error => {
//                 dispatch(skillFindByIdFail(error));
//             });
//     };
// };


// Delete


export const skillDeleteStart = () => {
    return {
        type: actionTypes.SKILL_DELETE_START
    };
};

export const skillDeleteSuccess = () => {
    return {
        type: actionTypes.SKILL_DELETE_SUCCESS,
    };
};

export const skillDeleteFail = error => {
    return {
        type: actionTypes.SKILL_DELETE_FAIL,
        error: error
    };
};


const deleteSkillHelper = (dispatch, id, catId, token) => {
    dispatch(skillDeleteStart());

    axios.delete('/skills/' + catId + '/' + id + '.json?auth=' + token)
        .then(response => {
            dispatch(skillDeleteSuccess());
            fetchSkillsHelper(dispatch);

        })
        .catch(error => {
            dispatch(skillDeleteFail(error));
        });
};

export const deleteSkill = (id, catId, token) => {
    return dispatch => {
        deleteSkillHelper(dispatch, id, catId, token);
    };
};