import React, { Component } from 'react';
import ImgsViewer from 'react-images-viewer';
import counterpart from 'counterpart';

import CircleChart from '../../UI/CircleChart/CircleChart';
import TextHeader from '../../UI/TextHeader/TextHeader';

import classes from './LanguageChart.module.css';

import TransCap from '../../TransCap/TransCap';

class LanguageChart extends Component {
    state = {
        isViewerOpen: false,
        currentImage: ''
    };

    viewImageHandler = image => {
        this.setState({
            ...this.state,
            currentImage: image,
            isViewerOpen: true
        })
    };

    closeViewerHandler = () => {
        this.setState({
            ...this.state,
            currentImage: '',
            isViewerOpen: false
        })
    }

    render() {
        const lang = counterpart.getLocale();

        let files = <p><TransCap text="noDocumentsAvailable" /></p>;

        if (this.props.docs.length > 0) {
            files = (<ul>
                {this.props.docs.map(f => (
                    <li key={f.id} className="mb-2">
                        <span className="mr-3"> {lang === 'fr' ? f.title_FR : f.title_EN}</span>
                        {
                            f.fileType === 'application/pdf' || f.fileType === 'application/msword' || f.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                ? (<a
                                    href={f.fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Télécharger"
                                    className="btn btn-warning btn-sm mr-3">
                                    <i className="fa fa-cloud-download" aria-hidden="true"></i>
                                </a>)
                                : null
                        }
                        {
                            f.fileType === 'image/jpg' || f.fileType === 'image/jpeg' || f.fileType === 'image/png'
                                ? (<button
                                    onClick={() => this.viewImageHandler(f.fileUrl)}
                                    title="Afficher"
                                    className="btn btn-primary btn-sm">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                </button>)
                                : null
                        }
                    </li>
                ))}
            </ul>)
        }

        return (
            <div className={classes.LanguageChart}>

                <TextHeader text={this.props.name} />

                <div className={classes.LanguageBox} >
                    <div className="row mb-4">
                        <div className="col-lg-6">
                            <CircleChart text=<TransCap text='mainPages.languages.read' /> value={this.props.read} size='small' />
                            <CircleChart text=<TransCap text='mainPages.languages.write' /> value={this.props.write} size='small' />
                            <CircleChart text=<TransCap text='mainPages.languages.speak' /> value={this.props.speak} size='small' />
                            <div className="clearfix"></div>

                        </div>
                        <div className="col-lg-6">

                            <div className={classes.Documents}>
                                <hr />
                                <h3 className="mb-4"><TransCap text="documents" /></h3>

                                {files}
                                <ImgsViewer
                                    imgs={[{ src: this.state.currentImage }]}
                                    currImg={0}
                                    isOpen={this.state.isViewerOpen}
                                    onClose={this.closeViewerHandler}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default LanguageChart;