import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initalState = {
    data: [],
    loading: false,
    deleting: false,
    fileDeleted: false,
    posted: false,
    error: null,
    progress: 0.0
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        // Add
        case actionTypes.COURSE_ADD_INIT:
            return updateObject(state, { loading: false, posted: false, error: null });

        case actionTypes.COURSE_ADD_START:
            return updateObject(state, { loading: true });

        case actionTypes.COURSE_ADD_SUCCESS:
            const newCourse = updateObject(action.data, { id: action.id });
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
                data: state.data.concat(newCourse)
            });

        case actionTypes.COURSE_ADD_FAIL:
            return updateObject(state, { loading: false, posted: false, error: action.error });

        // Update
        case actionTypes.COURSE_UPDATE_SUCCESS:
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
            });


        // Fetch
        case actionTypes.COURSE_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.COURSE_FETCH_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data.sort((a,b) => a.title.localeCompare(b.title))
            });

        case actionTypes.COURSE_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Find by ID
        case actionTypes.COURSE_FINDBYID_START:
            return updateObject(state, { loading: true });

        case actionTypes.COURSE_FINDBYID_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });
        case actionTypes.COURSE_FINDBYID_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Delete
        case actionTypes.COURSE_DELETE_START:
            return updateObject(state, { deleting: true, });

        case actionTypes.COURSE_DELETE_SUCCESS:
            return updateObject(state, { deleting: false });

        case actionTypes.COURSE_DELETE_FAIL:
            return updateObject(state, { deleting: false, error: action.error });

        // Upload

        case actionTypes.COURSE_UPLOADFILE_START:
            return updateObject(state, { uploaded: false });

        case actionTypes.COURSE_UPLOADFILE_PROGRESS:
            return updateObject(state, { progress: action.progress });

        case actionTypes.COURSE_UPLOADFILE_SUCCESS:
            //  const newDataFile = updateObject(state.data, action.data);

            return updateObject(state, { uploaded: true, progress: 0.0 });

        case actionTypes.COURSE_UPLOADFILE_FAIL:
            return updateObject(state, { uploaded: false, error: action.error });

        case actionTypes.COURSE_DELETE_FILE:
            return updateObject(state, { fileDeleted: true });

        case actionTypes.COURSE_DELETE_FILE_FAIL:
            return updateObject(state, { fileDeleted: false });

        default:
            return state;
    }
};

export default reducer;