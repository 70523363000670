import React from 'react';
import { NavLink } from 'react-router-dom';

import TransCap from '../../TransCap/TransCap';


import classes from './MainInfo.module.css';

const mainInfo = props => {
    const clsEN = props.lang === 'en' ? classes.EN : '';
    const clsFR = props.lang === 'fr' ? classes.FR : '';
    
    return (
        <div className="row">
            <div className="col-lg-12 text-center">
                <div className={classes.Info}>
                    <div className="img-responsive text-center">
                    <div className={classes.Languages}>
                        <button 
                            className={['btn-link', clsFR].join(' ')}
                            onClick={props.setLangFR}>
                            FR 
                        </button>
                        |
                        <button
                            className={['btn-link', clsEN].join(' ')}
                            onClick={props.setLangEN}>
                            EN 
                        </button>
                    </div>
                        <img className={classes.PImage} alt="" src={props.imageUrl} />
                    </div>
                    <h1>{props.firstName} {props.lastName}</h1>
                    <p>{props.title}</p>
                    <div className={classes.InfoBox}>
                        <p>
                            <a href={'mailto:' + props.email} >
                                {props.email}
                            </a>
                        </p>
                        <p><a href={'tel:' + props.phone}> {props.phone} </a></p>
                        <NavLink
                            to="/contact-me"
                            className="btn btn-dark btn-block" >
                            <i className="fa fa-envelope" aria-hidden="true"></i>  <TransCap text='mainNavigation.contactMe' />
                        </NavLink>
                    </div>
                    <div className={classes.InfoBox}>
                        <dl>
                            <dt><TransCap text='address.address' /></dt>
                            <dd>{props.street}</dd>
                            <dd>{props.zipCode} {props.city}, {props.country}</dd>
                        </dl>
                    </div>
                    <div className={classes.InfoBox}>
                        <p><strong><TransCap text='dateOfBirth' /> </strong>{props.dateOfBirth}</p>
                        <p><strong><TransCap text='maritalStatus' />: </strong>{props.maritalStatus}</p>
                    </div>
                    <p className={classes.SocialLinks}>
                        
                        {
                            props.linkedin
                            ?  <a href={props.linkedin} target="_blank"
                                    rel="noopener noreferrer"
                                    title="Mon Compte LinkedIn">
                                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                </a>

                            :null
                        }
                         {
                            props.github
                            ?  <a href={props.github} target="_blank" rel="noopener noreferrer"
                                    title="Mon Compte GitHub">
                                    <i className="fa fa-github-square" aria-hidden="true"></i>
                                </a>

                            :null
                        }
                         {
                            props.facebook
                            ?  <a href={props.facebook} target="_blank"
                                    rel="noopener noreferrer"
                                    title="Mon Compte Facebook">
                                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                </a>

                            :null
                        }

                    </p>
                </div>
                <button

                    className="btn btn-warning btn-block"
                    onClick={props.modalOpen}>
                    <TransCap text='download' /> <TransCap text='cv' />
                </button>
            </div>
        </div>
    );
}

export default mainInfo;