export default {
    address: {
        address: 'adresse',
        street: 'rue',
        city: 'ville',
        country: 'pays'
    },
    dateOfBirth: 'date de naissance',
    maritalStatus: 'état civil',
    myLinkedInAccount: 'mon compte LinkedIn',
    myGitHubAccount: 'mon compte GitHub',
    download: 'Télécharger',
    cv: 'CV',
    mainNavigation: {
        home: 'accueil',
        education: 'éducation',
        experience: 'expérience',
        skills: 'compétences',
        trainingCourses: 'formation',
        languages: 'langues',
        contactMe: 'contactez-moi',
        projects: 'mes projets',
    },
    mainPages: {
        home: {
            aboutMe: 'à propos de moi',
            aboutWebsite: 'à propos de ce site',
            developedBy: 'développé par moi',
            techsUsed: 'Technologies utilisées'
        },
        education: {
            myDegrees: 'mes diplômes',
            years: 'années',
            mention: 'mention',
            moreDetails: 'plus de détails',
            back: 'retour'
        },
        experience: {
            myExperience: 'mon expérience',
            responisbilites: 'responsabilités',
            noExperiencesYet: 'pas encore d\'expériences'
        },
        projects: {
            myProjects: 'mes projets',
            noProjectsYet: 'aucun project disponible',
            chooseProject: 'Veuillez choisir un projet.'
        },
        courses:{
            provider: 'fournisseur',
            duration: 'durée',
            type: 'type',
            url: 'url'
        },
        languages: {
            read: 'lu',
            write: 'écrit',
            speak: 'parlé'
        },
        contactMe: {
            sendMeAMessage: 'Envoyez-moi un message',
            name: 'nom',
            namePH: 'veuillez saisir votre nom',
            email: 'e-mail',
            emailPH: 'veuillez saisir votre e-mail',
            subject: 'objet',
            subjectPH: 'veuillez saisir l\'objet du message',
            message: 'message',
            messagePH: 'veuillez saisir votre message',
            send: 'envoyer',
            sent: 'Votre message a été envoyé. Merci !'
        }
    },
    admin : {
        visits: {
            websiteVisits: 'visites du site',
            visitorsCount: 'nombre de visiteurs',
            totalVisitsCount: 'nombre total de visites',
            visitsCount: 'nombre de visites',
            totalVisits: 'total des visites'
        }
    },
    documents: 'documents',
    noDocumentsAvailable: 'aucun document disponible',
    adminPages: {
        personalInfo:{
            personalInfo: 'information personnelle'
        }
    },
    unavailable: 'indisponible',
    selectFile: 'Veuillez choisir un fichier',
    downloadMyCV: 'Télécharger mon CV',
    close: 'Fermer',
    now: 'Actuellement',
    projectType: 'Type de projet',
    date: 'La date',
    close: 'Fermer',
    details: 'Détails',
    dateAndTime: 'Date & l\'heure',
    error: 'Erreur',
}