import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';

export const languageAddInit = () => {
    return {
        type: actionTypes.LANGUAGE_ADD_INIT
    };
}
export const languageAddStart = _ => {
    return {
        type: actionTypes.LANGUAGE_ADD_START
    };
};

export const languageAddSuccess = (id, data) => {
    return {
        type: actionTypes.LANGUAGE_ADD_SUCCESS,
        id: id,
        data: data
    };
};

export const languageUpdateSuccess = (data) => {
    return {
        type: actionTypes.LANGUAGE_UPDATE_SUCCESS,
        data: data
    };
};


export const languageAddFail = error => {
    return {
        type: actionTypes.LANGUAGE_ADD_FAIL,
        error: error
    };
};


export const addLanguage = (data, token) => {
    return dispatch => {
        dispatch(languageAddStart());

        axios.post('/languages.json?auth=' + token, data)
            .then(response => {
                dispatch(languageAddSuccess(response.data.name, data));
            })
            .catch(error => {
                console.log(error);
                dispatch(languageAddFail(error));
            });
    };
};

export const updateLanguage = (data, token, eduId) => {
    return dispatch => {
        dispatch(languageAddStart());

        axios.patch('/languages/' + eduId + '.json?auth=' + token, data)
            .then(response => {
                dispatch(languageUpdateSuccess(data));
            })
            .catch(error => {

                dispatch(languageAddFail(error));
            });

    };
};

// Fetch

export const languageFetchStart = _ => {
    return {
        type: actionTypes.LANGUAGE_FETCH_START
    };
};

export const languageFetchSuccess = (data) => {
    return {
        type: actionTypes.LANGUAGE_FETCH_SUCCESS,
        data: data
    };
};

export const languageFetchFail = error => {
    return {
        type: actionTypes.LANGUAGE_FETCH_FAIL,
        error: error
    };
};

const fetchLanguagesHelper = dispatch => {
    axios.get('/languages.json')
        .then(response => {

            const fetchedData = [];
            for (let key in response.data) {
                fetchedData.push({
                    ...response.data[key],
                    id: key,
                });
            }

            dispatch(languageFetchSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(languageFetchFail(error));
        });
};


export const fetchLanguages = () => {
    return dispatch => {
        dispatch(languageFetchStart());

        fetchLanguagesHelper(dispatch);
    };
};

/// Find By Id

export const languageFindByIdStart = () => {
    return {
        type: actionTypes.LANGUAGE_FINDBYID_START
    };
};

export const languageFindByIdSuccess = (data) => {
    return {
        type: actionTypes.LANGUAGE_FINDBYID_SUCCESS,
        data: data
    };
};

export const languageFindByIdFail = error => {
    return {
        type: actionTypes.LANGUAGE_FINDBYID_FAIL,
        error: error
    };
};

const languageFindByIdHelper = (dispatch, id) => {
    dispatch(languageFindByIdStart());

    axios.get('/languages/' + id + '.json')
        .then(response => {

            const fetchedData = [];

            fetchedData.push({
                ...response.data,
                id: id,
            });

            dispatch(languageFindByIdSuccess(fetchedData));
        })
        .catch(error => {
            dispatch(languageFindByIdFail(error));
        });
};

export const languageFindById = (id) => {
    return dispatch => {
        languageFindByIdHelper(dispatch, id);
    };
};


// Delete


export const languageDeleteStart = () => {
    return {
        type: actionTypes.LANGUAGE_DELETE_START
    };
};

export const languageDeleteSuccess = () => {
    return {
        type: actionTypes.LANGUAGE_DELETE_SUCCESS,
    };
};

export const languageDeleteFail = error => {
    return {
        type: actionTypes.LANGUAGE_DELETE_FAIL,
        error: error
    };
};


export const deleteLanguage = (id, token) => {
    return dispatch => {
        dispatch(languageDeleteStart());

        axios.delete('/languages/' + id + '.json?auth=' + token)
            .then(response => {
                dispatch(languageDeleteSuccess());
                fetchLanguagesHelper(dispatch);

            })
            .catch(error => {
                dispatch(languageDeleteFail(error));
            });
    };
};

// Upload File


export const uploadFileStart = () => {
    return {
        type: actionTypes.LANGUAGE_UPLOADFILE_START
    };
};

export const uploadFileProgress = progress => {
    return {
        type: actionTypes.LANGUAGE_UPLOADFILE_PROGRESS,
        progress: progress
    };
};

export const uploadFileSuccess = (id) => {
    return {
        type: actionTypes.LANGUAGE_UPLOADFILE_SUCCESS,
        id: id
    };
};

export const uploadFileFail = error => {
    return {
        type: actionTypes.LANGUAGE_UPLOADFILE_FAIL,
        error: error
    };
};

export const languageUploadFile = (data, token, cfile) => {
    return dispatch => {
        dispatch(uploadFileStart());

        const file = cfile;
        const fname = new Date().getTime() + '_' + file.name;

        const uploadTask = storage.ref(`languages/${fname}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                dispatch(uploadFileProgress(progress));
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(uploadFileFail(error));
            },
            () => {
                // complete function ....
                storage.ref('languages').child(fname).getDownloadURL().then(url => {

                    const dataWithFile = {
                        ...data,
                        fileUrl: url,
                        fileName: fname,
                        fileSize: file.size,
                        fileType: file.type
                    }

                    axios.post('/languages/' + data.langId + '/files.json?auth=' + token, dataWithFile)
                        .then(response => {
                            dispatch(uploadFileSuccess(response.data.name));
                            languageFindByIdHelper(dispatch, data.langId);
                        })
                        .catch(error => {
                            dispatch(uploadFileFail(error));
                        });
                })
            });
    };
};


// Delete file

export const deleteFileStart = () => {
    return {
        type: actionTypes.LANGUAGE_DELETE_FILE_START
    }
};

export const deleteFileSuccess = () => {
    return {
        type: actionTypes.LANGUAGE_DELETE_FILE_SUCCESS
    }
};

export const deleteFileFail = error => {
    return {
        type: actionTypes.LANGUAGE_DELETE_FILE_FAIL,
        errorDeleteFile: error
    }
};

export const languageDeleteFile = (id, fileName, langId, token) => {
    return dispatch => {
        dispatch(deleteFileStart());
        const fileRef = storage.ref().child('languages/' + fileName);

        fileRef.delete().then(() => {
            axios.delete('/languages/' + langId + '/files/' + id + '.json?auth=' + token)
                .then(response => {
                    dispatch(deleteFileSuccess());
                    languageFindByIdHelper(dispatch, langId);
                })
                .catch(error => {
                    dispatch(deleteFileFail(error));
                });
        }).catch(error => {
            dispatch(deleteFileFail());
        });
    }
};