import React, { Component } from 'react';

import { connect } from 'react-redux';

import axios from '../../../axios-firebase';

import * as actions from '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler';

import Spinner from '../../../components/UI/Spinner/Spinner';
import TextHeader from '../../../components/UI/TextHeader/TextHeader';

import Skill from '../../../components/Main/Skills/Skill';

import classes from './Skills.module.css';

import TransCap from '../../../components/TransCap/TransCap';


class Skills extends Component {
    state = {
        skills: []
    };

    componentDidMount() {
        this.props.onFetchSkills();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const st = {
            ...prevState,
            skills: [...nextProps.data]
        };

        return st;
    }

    render() {

        let skills = <Spinner size='100px' />

        if (!this.props.loading) {
            skills = this.state.skills.map(c => (
                <div className="mb-3" key={c.id}>
                    <TextHeader
                        
                        text={<span style={{ textTransform: 'capitalize' }}>{c.id.split('-').join(' ')}</span>}
                    />
                    <div className={classes.SkillBox}>
                        {
                            c.items.map(s => (

                                <Skill
                                    key={s.id}
                                    id={s.id}
                                    name={s.name}
                                    level={s.level}
                                />

                            ))
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
            ))
        }

        return (
            <div className={classes.Skills}>
                <h2 className="mb-5"> <i className="fa fa-star" aria-hidden="true"></i> <TransCap text="mainNavigation.skills" /></h2>
                {skills}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state.cvSkills.data,
        loading: state.cvSkills.loading,
        error: state.cvSkills.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchSkills: () => dispatch(actions.fetchSkills()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Skills, axios));