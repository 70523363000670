import React, { Component } from 'react';
import ImgsViewer from 'react-images-viewer';
import counterpart from 'counterpart';

import TextHeader from '../../UI/TextHeader/TextHeader';

import classes from './Course.module.css';

import TransCap from '../../TransCap/TransCap';

class Course extends Component {

    state = {
        isViewerOpen: false,
        currentImage: ''
    };

    viewImageHandler = image => {
        this.setState({
            ...this.state,
            currentImage: image,
            isViewerOpen: true
        })
    };

    closeViewerHandler = () => {
        this.setState({
            ...this.state,
            currentImage: '',
            isViewerOpen: false
        })
    }

    render() {
        const lang = counterpart.getLocale();
        let files = null;
        if (this.props.docs.length > 0) {
            files = (
                <div className="row">
                    <div className="col-lg-12">
                        <ul>
                            <li>
                                <strong><TransCap text="documents" />:</strong>
                                <ul>
                                    {this.props.docs.map(f => (
                                        <li key={f.id}>
                                            <span className="mr-3"> {lang === 'fr' ? f.title_FR : f.title_EN}</span>
                                            {
                                                f.fileType === 'application/pdf' || f.fileType === 'application/msword' || f.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                    ? (<a
                                                        href={f.fileUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title="Télécharger"
                                                        className="btn btn-warning btn-sm mr-3">
                                                        <i className="fa fa-cloud-download" aria-hidden="true"></i>
                                                    </a>)
                                                    : null
                                            }
                                            {
                                                f.fileType === 'image/jpg' || f.fileType === 'image/jpeg' || f.fileType === 'image/png'
                                                    ? (<button
                                                        onClick={() => this.viewImageHandler(f.fileUrl)}
                                                        title="Afficher"
                                                        className="btn btn-primary btn-sm">
                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                    </button>)
                                                    : null
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
        return (
            <div className={classes.Course}>
                <TextHeader text={this.props.title} />

                <div className={classes.CourseBox}>
                    <div className="row">
                        <div className="col-lg-6">
                            <ul>
                                <li><strong><TransCap text="mainPages.courses.provider" />:</strong> {this.props.provider}</li>
                                <li><strong><TransCap text="mainPages.courses.duration" />:</strong> {this.props.duration} hrs</li>
                                <li><strong><TransCap text="mainPages.courses.type" />:</strong> {this.props.type}</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <ul>
                                <li><strong><TransCap text="address.city" />: </strong> {this.props.city ? this.props.city : <span className="text-warning small"><TransCap text="unavailable" /></span>}</li>
                                <li><strong><TransCap text="address.country" />: </strong> {this.props.country ? this.props.country : <span className="text-warning small"><TransCap text="unavailable" /></span>} </li>
                                <li>
                                    <strong>URL: </strong>
                                    {
                                        this.props.url
                                            ? <a href={this.props.url} target="_blank" rel="noopener noreferrer" >
                                               &nbsp; <i className="fa fa-external-link" aria-hidden="true"></i>
                                            </a>
                                            : <span className="text-warning small"><TransCap text="unavailable" /></span>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                    {files}

                    <ImgsViewer
                        imgs={[{ src: this.state.currentImage }]}
                        currImg={0}
                        isOpen={this.state.isViewerOpen}
                        onClose={this.closeViewerHandler}
                    />
                </div>


            </div>
        );
    }
}

export default Course;