import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import './fonts/OpenSans-Regular.ttf';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import authReducer from './store/reducers/auth';
import cvPersonalInfoReducer from './store/reducers/cvPersonalInfo';
import cvContactInfoReducer from './store/reducers/cvContactInfo';
import cvExperiencesReducer from './store/reducers/cvExperiences';
import cvEducationsReducer from './store/reducers/cvEducation';
import cvLanguagesReducer from './store/reducers/cvLanguages';
import cvCoursesReducer from './store/reducers/cvCourses';
import cvSkillsReducer from './store/reducers/cvSkills';
import mainInfoReducer from './store/reducers/mainInfo';
import messagesReducer from './store/reducers/messages';
import projectsReducer from './store/reducers/projects';
import visitsReducer from './store/reducers/visits';

// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    cvPersonalInfo: cvPersonalInfoReducer,
    cvContactInfo: cvContactInfoReducer,
    cvExperiences: cvExperiencesReducer,
    cvEducations: cvEducationsReducer,
    cvLanguages: cvLanguagesReducer,
    cvCourses: cvCoursesReducer,
    cvSkills: cvSkillsReducer,
    mainInfo: mainInfoReducer,
    messages: messagesReducer,
    projects: projectsReducer,
    visits: visitsReducer
});

// const store = createStore(rootReducer, composeEnhancers(
//     applyMiddleware(thunk)
// ));

const store = createStore(rootReducer,
    applyMiddleware(thunk)
);



const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
