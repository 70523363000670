import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';

import axios from '../../../axios-firebase';
import withErrorHandler from '../../../hoc/withErrorHandler';
import * as actions from '../../../store/actions/index';

import Spinner from '../../../components/UI/Spinner/Spinner';

import classes from './Projects.module.css';

import TransCap from '../../../components/TransCap/TransCap';

import ProjectDetails from './ProjectDetails/ProjectDetails';

class Projects extends Component {

    state = {
        selectedProject: null
    }

    componentDidMount() {
        this.props.onFetchProjects();
    }

    selectProjectHandler = id => {
        const prj = this.props.data.filter(p => p.id === id);

        this.setState({
            ...this.state,
            selectedProject: prj[0]
        });

        // console.log(this.state.selectedProject);
    }

    render() {
        const lang = counterpart.getLocale();
        let projectsList = <Spinner size="100px" />;
        let projects = null;
        let projectsRender = null;

        if (!this.props.loading) {

            projects = this.props.data.map(p => (
                <button
                    key={p.id}
                    onClick={() => this.selectProjectHandler(p.id)}
                    className="list-group-item list-group-item-action bg-dark"
                    style={{ color: 'lightgrey' }}>
                    {p.type} -> {p.title}
                </button>
            ));

            projectsList =
                (
                    <div className="list-group">
                        {projects}
                    </div>
                );

            projectsRender = (
                <div className="row mt-4">
                    <div className="col-lg-3">
                        {projectsList}
                    </div>
                    <div className="col-lg-9" style={{borderLeft:'1px solid lightgrey'}}>
                        <div className={classes.Project}>

                            {
                                this.state.selectedProject
                                    ? <ProjectDetails
                                        title={this.state.selectedProject.title}
                                        type={this.state.selectedProject.type}
                                        tags={this.state.selectedProject.tags}
                                        description={lang === 'fr' ? this.state.selectedProject.description_FR : this.state.selectedProject.description_EN}
                                        githubUrl={this.state.selectedProject.githubUrl}
                                        demoUrl={this.state.selectedProject.demoUrl} />
                                    : <h3 className="text-center"><TransCap text="mainPages.projects.chooseProject" /></h3>
                            }

                        </div>
                    </div>
                </div>
            );

            if(projects.length === 0){
                projectsRender = <h3 className="alert alert-info text-center"><TransCap text="mainPages.projects.noProjectsYet" /></h3>;
            }
        }

        return (
            <div className={classes.Projects}>
                <h2 style={{ color: 'lightgrey' }}> <i className="fa fa-star" aria-hidden="true"></i> <TransCap text="mainPages.projects.myProjects" /></h2>
                {projectsRender}
               
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        data: state.projects.data,
        loading: state.projects.loading,
        error: state.projects.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchProjects: () => dispatch(actions.fetchProjects()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Projects, axios));