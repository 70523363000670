import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';
import { storage } from '../../firebase';


//// Update data

export const personalInfoUpdateStart = _ => {
    return {
        type: actionTypes.PERSONAL_INFO_UPDATE_START
    };
};

export const personalInfoUpdateSuccess = (data) => {
    return {
        type: actionTypes.PERSONAL_INFO_UPDATE_SUCCESS,
        data: data
    };
};

export const personalInfoUpdateFail = error => {
    return {
        type: actionTypes.PERSONAL_INFO_UPDATE_FAIL,
        error: error
    };
};

export const update = (data, token, imageData) => {
    return dispatch => {
        dispatch(personalInfoUpdateStart());

        if (imageData) {

            const image = imageData;

            const uploadTask = storage.ref(`images/${image.name}`).put(image);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                },
                (error) => {
                    // error function ....
                    //console.log(error);
                    dispatch(personalInfoUpdateFail(error));
                },
                () => {
                    // complete function ....
                    storage.ref('images').child(image.name).getDownloadURL().then(url => {

                        const dataWithImage = {
                            ...data,
                            imageUrl: url,
                            image: {
                                imageUrl: url,
                                imageSize: image.size,
                                imageName: image.name
                            }
                        }

                        axios.put('/personal-info.json?auth=' + token, dataWithImage)
                            .then(response => {
                                dispatch(personalInfoUpdateSuccess(data));
                            })
                            .catch(error => {
                                dispatch(personalInfoUpdateFail(error));
                            });
                    })
                });
        } else {
            axios.patch('/personal-info.json?auth=' + token, data)
                .then(response => {
                    dispatch(personalInfoUpdateSuccess(data));
                })
                .catch(error => {
                    dispatch(personalInfoUpdateFail(error));
                });
        }

    };
};


//// Fetch data

export const personalInfoFetchStart = _ => {
    return {
        type: actionTypes.PERSONAL_INFO_FETCH_START
    };
};

export const personalInfoFetchSuccess = data => {
    return {
        type: actionTypes.PERSONAL_INFO_FETCH_SUCCESS,
        data: data
    };
};

export const personalInfoFetchFail = error => {
    return {
        type: actionTypes.PERSONAL_INFO_FETCH_FAIL,
        error: error
    };
};

export const fetch = () => {
    return dispatch => {
        dispatch(personalInfoFetchStart());
        axios.get('/personal-info.json')
            .then(response => {
                dispatch(personalInfoFetchSuccess(response.data));
            })
            .catch(error => {
                dispatch(personalInfoFetchFail(error));
            });
    };
};


//// Fetch About Me

export const personalInfoAboutMeFetchStart = _ => {
    return {
        type: actionTypes.PERSONAL_INFO_ABOUTME_FETCH_START
    };
};

export const personalInfoAboutMeFetchSuccess = data => {
    return {
        type: actionTypes.PERSONAL_INFO_ABOUTME_FETCH_SUCCESS,
        aboutMe_FR: data.about_FR,
        aboutMe_EN: data.about_EN,
    };
};

export const personalInfoAboutMeFetchFail = error => {
    return {
        type: actionTypes.PERSONAL_INFO_ABOUTME_FETCH_FAIL,
        error: error
    };
};

export const fetchAboutMe = (lang) => {
    return dispatch => {
        dispatch(personalInfoAboutMeFetchStart());

        axios.get('/personal-info/about_FR.json')
            .then(responseFR => {
                axios.get('/personal-info/about_EN.json')
                    .then(responseEN => {
                        const res = {
                            about_FR: responseFR.data,
                            about_EN: responseEN.data
                        };

                        dispatch(personalInfoAboutMeFetchSuccess(res));
                    })
                    .catch(error => {
                        dispatch(personalInfoAboutMeFetchFail(error));
                    });
            })
            .catch(error => {
                dispatch(personalInfoAboutMeFetchFail(error));
            });
    };
};
// Upload CV


export const uploadCVStart = () => {
    return {
        type: actionTypes.PERSONAL_INFO_UPLOADCV_START
    };
};

export const uploadCVProgress = progress => {
    return {
        type: actionTypes.PERSONAL_INFO_UPLOADCV_PROGRESS,
        progress: progress
    };
};

export const uploadCVSuccess = (id, data) => {
    return {
        type: actionTypes.PERSONAL_INFO_UPLOADCV_SUCCESS,
        data: data,
        id: id
    };
};

export const uploadCVFail = error => {
    return {
        type: actionTypes.PERSONAL_INFO_UPLOADCV_FAIL,
        error: error
    };
};

export const uploadCv = (data, token, cvFile) => {
    return dispatch => {
        dispatch(uploadCVStart());

        const file = cvFile;
        const fname = new Date().getTime() + '_' + file.name;

        const uploadTask = storage.ref(`cv/${fname}`).put(file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // progrss function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                dispatch(uploadCVProgress(progress));
            },
            (error) => {
                // error function ....
                //console.log(error);
                dispatch(uploadCVFail(error));
            },
            () => {
                // complete function ....
                storage.ref('cv').child(fname).getDownloadURL().then(url => {

                    const dataWithFile = {
                        ...data,
                        fileUrl: url,
                        fileName: fname,
                        fileSize: cvFile.size
                    }

                    axios.post('/personal-info/cv-files.json?auth=' + token, dataWithFile)
                        .then(response => {
                            dispatch(uploadCVSuccess(response.data.name, data));
                            fetchCvsHelper(dispatch);
                        })
                        .catch(error => {
                            dispatch(uploadCVFail(error));
                        });
                })
            });


    };
};


// Fetch CVs

export const fetchCvs = data => {
    return {
        type: actionTypes.PERSONAL_INFO_FETCH_CVS,
        cvFiles: data
    }
};

export const fetchCvsFail = () => {
    return {
        type: actionTypes.PERSONAL_INFO_FETCH_CVS_FAIL,
    }
};



const fetchCvsHelper = (dispatch) => {
    axios.get('/personal-info/cv-files.json')
        .then(response => {
            const fetchedFiles = [];

            for (let key in response.data) {
                fetchedFiles.push({
                    ...response.data[key],
                    id: key,
                });
            }
            dispatch(fetchCvs(fetchedFiles));
        }).catch(error => {
            dispatch(fetchCvsFail());
        });
};

export const fetchCVs = () => {
    return dispatch => {
        fetchCvsHelper(dispatch);
    }
};

export const deleteCv = () => {
    return {
        type: actionTypes.PERSONAL_INFO_DELETE_CV
    }
};

export const deleteCvFail = () => {
    return {
        type: actionTypes.PERSONAL_INFO_DELETE_CV_FAIL
    }
};

export const deleteCV = (id, fileName, token) => {
    return dispatch => {

        var fileRef = storage.ref().child('cv/' + fileName);

        fileRef.delete().then(() => {
            axios.delete('/personal-info/cv-files/' + id + '.json?auth=' + token)
                .then(response => {
                    dispatch(deleteCv());
                    fetchCvsHelper(dispatch);
                })
                .catch(error => {
                    dispatch(deleteCvFail());
                });
        }).catch(error => {
            dispatch(deleteCvFail());
        });
    }
};


