import * as actionTypes from './actionTypes';

import axios from '../../axios-firebase';


export const mainInfoFetchStart = _ => {
    return {
        type: actionTypes.MAIN_INFO_FETCH_START
    };
};

export const mainInfoFetchSuccess = data => {
    return {
        type: actionTypes.MAIN_INFO_FETCH_SUCCESS,
        data: data
    };
};

export const mainInfoFetchFail = error => {
    return {
        type: actionTypes.MAIN_INFO_FETCH_FAIL,
        error: error
    };
};

export const fetchMainInfo = () => {
    return dispatch => {
        dispatch(mainInfoFetchStart());
        axios.get('/personal-info.json')
            .then(responseP => {
                const dataPersonalInfo = responseP.data;

                axios.get('/contact-info.json')
                    .then(repsonseC => {
                        const dataContactInfo = repsonseC.data;

                        const data = {
                            ...dataPersonalInfo,
                            ...dataContactInfo
                        };

                        dispatch(mainInfoFetchSuccess(data));
                    })
                    .catch(error => {
                        dispatch(mainInfoFetchFail(error));
                    });

            })
            .catch(error => {
                dispatch(mainInfoFetchFail(error));
            });
    };
};