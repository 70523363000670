import React from 'react';

import classes from './NavItems.module.css';
import NavItem from './NavItem/NavItem';

import TransCap from '../../TransCap/TransCap';

const navItems = (props) => (
    <ul className={classes.NavItems}>
        <NavItem link="/" exact> <i className="fa fa-home" aria-hidden="true"></i> <TransCap text="mainNavigation.home" /> </NavItem>
        <NavItem link="/education" exact><i className="fa fa-graduation-cap" aria-hidden="true"></i> <TransCap text="mainNavigation.education" /></NavItem>
        <NavItem link="/experience" exact><i className="fa fa-briefcase" aria-hidden="true"></i> <TransCap text="mainNavigation.experience" /></NavItem>
        <NavItem link="/skills" exact><i className="fa fa-star" aria-hidden="true"></i> <TransCap text="mainNavigation.skills" /></NavItem>
        <NavItem link="/training-courses" exact><i className="fa fa-leanpub" aria-hidden="true"></i> <TransCap text="mainNavigation.trainingCourses" /></NavItem>
        <NavItem link="/languages" exact><i className="fa fa-language" aria-hidden="true"></i> <TransCap text="mainNavigation.languages" /></NavItem>
        {/* <NavItem link="/contact-me" exact><i className="fa fa-envelope" aria-hidden="true"></i> <TransCap text="mainNavigation.contactMe" /></NavItem> */}
        <NavItem link="/projects" exact><i className="fa fa-star" aria-hidden="true"></i> <TransCap text="mainNavigation.projects" /></NavItem>



    </ul>
);

export default navItems;