import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    data: {},
    aboutMe_FR: '',
    aboutMe_EN: '',
    loadingAboutMe: false,
    cvFiles: [],
    loading: false,
    uploaded: false,
    deleted: false,
    progress: 0,
    error: null,
    isFetch: false,
    fetchCvsFailed: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PERSONAL_INFO_UPDATE_START:
            return updateObject(state, { loading: true, isFetch: false });

        case actionTypes.PERSONAL_INFO_UPDATE_SUCCESS:
            const newData = updateObject(state.data, action.data);

            return updateObject(state, { loading: false, isFetch: false, data: newData });

        case actionTypes.PERSONAL_INFO_UPDATE_FAIL:
            return updateObject(state, { loading: false, isFetch: false, error: action.error });

        case actionTypes.PERSONAL_INFO_FETCH_START:
            return updateObject(state, { loading: true, isFetch: true });

        case actionTypes.PERSONAL_INFO_FETCH_SUCCESS:
            const fetchedData = updateObject(state.data, action.data);

            return updateObject(state, { loading: false, isFetch: true, data: fetchedData });

        case actionTypes.PERSONAL_INFO_FETCH_FAIL:
            return updateObject(state, { loading: false, isFetch: true, error: action.error });


        case actionTypes.PERSONAL_INFO_ABOUTME_FETCH_START:
            return updateObject(state, { loadingAboutMe: true });

        case actionTypes.PERSONAL_INFO_ABOUTME_FETCH_SUCCESS:
            return updateObject(state, { loadingAboutMe: false, aboutMe_FR: action.aboutMe_FR, aboutMe_EN: action.aboutMe_EN });

        case actionTypes.PERSONAL_INFO_ABOUTME_FETCH_FAIL:
            return updateObject(state, { loadingAboutMe: false, error: action.error });


        case actionTypes.PERSONAL_INFO_UPLOADCV_START:
            return updateObject(state, { uploaded: false });

        case actionTypes.PERSONAL_INFO_UPLOADCV_PROGRESS:
            return updateObject(state, { progress: action.progress });

        case actionTypes.PERSONAL_INFO_UPLOADCV_SUCCESS:
            const newDataCv = updateObject(state.data, action.data);

            return updateObject(state, { uploaded: true, progress: 0.0, data: newDataCv });

        case actionTypes.PERSONAL_INFO_UPLOADCV_FAIL:
            return updateObject(state, { uploaded: false, error: action.error });

        case actionTypes.PERSONAL_INFO_FETCH_CVS:
            return updateObject(state, { cvFiles: action.cvFiles, deleted: false, fetchCvsFailed: false });

        case actionTypes.PERSONAL_INFO_FETCH_CVS_FAIL:
            return updateObject(state, { fetchCvsFailed: true });

        case actionTypes.PERSONAL_INFO_DELETE_CV:
            return updateObject(state, { deleted: true });

        case actionTypes.PERSONAL_INFO_DELETE_CV_FAIL:
            return updateObject(state, { deleted: false });

        default:
            return state;
    }
};


export default reducer;
