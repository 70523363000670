import React, { Component } from 'react';

import '../../App.scss';

// Containers
const DefaultLayout = React.lazy(() => import('../Admin/DefaultLayout'));


class Layout extends Component {

    render() {
        return (
            <DefaultLayout {...this.props} lang={this.props.lang} DlangChanged={this.props.langChanged} />
        );
    }
}

export default Layout;

