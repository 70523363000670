import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initialState = {
    data: null,
    loading: false,
    error: null,
    isFetch: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONTACT_INFO_UPDATE_START:
            return updateObject(state, { loading: true, isFetch: false });

        case actionTypes.CONTACT_INFO_UPDATE_SUCCESS:
            const newData = updateObject(state.data, action.data);

            return updateObject(state, { loading: false, isFetch: false, data: newData });

        case actionTypes.CONTACT_INFO_UPDATE_FAIL:
            return updateObject(state, { loading: false, isFetch: false, error: action.error });

        case actionTypes.CONTACT_INFO_FETCH_START:
            return updateObject(state, { loading: true, isFetch: true });

        case actionTypes.CONTACT_INFO_FETCH_SUCCESS:
            const fetchedData = updateObject(state.data, action.data);

            return updateObject(state, { loading: false, isFetch: true, data: fetchedData});

        case actionTypes.CONTACT_INFO_FETCH_FAIL:
            return updateObject(state, { loading: false, isFetch: true, error: action.error });

        default:
            return state;
    }
};


export default reducer;
