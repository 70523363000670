import React from 'react';

import classes from '../Timeline.module.scss';

import TransCap from '../../../TransCap/TransCap';

const timelineBlock = props => {

    let files = null;
    if (props.files && props.files.length > 0) {
        files = props.files.map(f => (
            <a
                key={f.id}
                href={f.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-dark btn-sm mt-2">
                <i className="fa fa-cloud-download" aria-hidden="true"></i> {f.title}
            </a>
        ))
    }

    return (
        <div className={classes["cd-timeline-block"]}>
            <div className={[classes["cd-timeline-img"], classes["cd-picture"]].join(' ')}>
            </div>

            <div className={classes["cd-timeline-content"]}>
                <h2>{props.post}</h2>
                <div className={classes["timeline-content-info"]}>
                    <span className={classes["timeline-content-info-title"]}>
                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                        {props.startDate}
                    </span>
                    <span className={classes["timeline-content-info-date"]}>
                        <i className="fa fa-calendar-o" aria-hidden="true"></i>
                        {props.finishDate ? props.finishDate : <TransCap text='now' />}
                    </span>
                </div>
                {/* <p>Working alongside the designer team implementing the designs, also developing custom solutions to address team necessities.</p> */}
                <ul className={classes["content-skills"]}>
                    <li> {props.company}</li>
                    {props.companyWebsite
                        ? <li> <a href={props.companyWebsite}> {props.companyWebsite}</a></li>
                        : null}
                    <li><TransCap text="address.city" />: {props.city}</li>
                    <li><TransCap text="address.country" />: {props.country}</li>
                </ul>
                <p>
                    <strong><TransCap text="mainPages.experience.responisbilites" />:</strong> {props.responsibilites}
                </p>
                {files ? <p><TransCap text="documents" /></p> : null}
                {files}
            </div>
        </div>
    );
};

export default timelineBlock;