import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import counterpart from 'counterpart';
import { store } from 'react-notifications-component';

import withErrorHandler from '../../hoc/withErrorHandler';
import axios from '../../axios-firebase';
import * as actions from '../../store/actions/index';

import MainInfo from './MainInfo/MainInfo';

import Modal from '../../components/UI/Modal/Modal';
import TransCap from '../../components/TransCap/TransCap';

import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

import classes from './Layout.module.css';
import AboutMe from './AboutMe/AboutMe';
import ContactMe from './ContactMe/ContactMe';
import Education from './Education/Education';
import EducationDetails from './Education/EducationDetails/EducationDetails';
import Experience from './Experience/Experience';
import Courses from './Courses/Courses';
import Skills from './Skills/Skills';
import Projects from './Projects/Projects';

import Languages from './Languages/Languages';

class Layout extends Component {

    state = {
        showSideDrawer: false,
        showContent: false,
        modal: false,
        lang: 'fr'
    };


    componentDidMount() {
        this.props.onFetchCvs();

        const publicIp = require('public-ip');
        let IPV4 = null;

        (async () => {
                IPV4 = await publicIp.v4();
            })().then(() => {
                // console.log(IPV4);
                const IPTEXT = IPV4.toString().split('.').join('-');
                const dt = new Date();
                axios.post('/visits/'+ IPTEXT  + '.json', {
                    IPV4: IPV4,
                    datetime: dt.getDate() + '/' + (+dt.getMonth() + 1) + '/' + dt.getFullYear() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
                })
            });
    }


    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    };


    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    };


    openModalHandler = () => {
        this.setState({ ...this.state, modal: true });
    };

    closeModalHandler = () => {
        this.setState({ ...this.state, modal: false });
    };

    setLang = lang => {
        this.setState({ ...this.state, lang: lang});
        counterpart.setLocale(lang);

        
        store.addNotification({
            title: lang === 'fr'? 'Langue Changée' :  'Language Changed !',
            message: lang === 'fr' ? 'Français' : 'English',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
                duration: 3000,
                onScreen: true,
                pauseOnHover: true
            },
            touchSlidingExit: {
                swipe: {
                duration: 400,
                timingFunction: 'ease-out',
                delay: 0,
                },
                fade: {
                duration: 400,
                timingFunction: 'ease-out',
                delay: 0
                }
            }
        });
    }

    render() {
        
        const clsEN = this.state.lang === 'en' ? classes.EN : '';
        const clsFR = this.state.lang === 'fr' ? classes.FR : '';

        let cvs = null;

        if (this.props.cvFiles) {
            cvs = this.props.cvFiles.map(cv => (
                <a
                    key={cv.id}
                    href={cv.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-secondary mr-2 mb-2 mt-1">
                    {cv.title} ({Math.ceil(cv.fileSize / 1000)}KB)
                </a>
            ));
        }

        return (
            <div className={classes.Layout}>

                <div className="container-fluid">
                    <div className="row">
                        <div className={["col-lg-3", classes.Left].join(' ')}>
                            

                            <MainInfo 
                                openModalHandler={this.openModalHandler} 
                                lang={this.state.lang} 
                                setLangEN={() => this.setLang('en')}
                                setLangFR={() => this.setLang('fr')} />
                        </div>
                        <div className={["col-lg-9", classes.Right].join(' ')}>
                            <div className={classes.NavTabs}>
                                <Toolbar
                                    drawerToggleClicked={this.sideDrawerToggleHandler} />
                                <SideDrawer
                                    closed={this.sideDrawerClosedHandler}
                                    open={this.state.showSideDrawer} />
                            </div>
                            <div className={classes.Comps}>
                                <Switch>
                                    <Route path='/education' exact component={Education} />

                                    {/* <Route path='/education/details/:id' component={EducationDetails} />                               */}

                                    <Route path='/experience' exact component={Experience} />
                                    {/* <Route path='/experience/details/:id' component={EducationDetails} /> */}

                                    <Route path='/languages' exact component={Languages} />
                                    <Route path='/training-courses' exact component={Courses} />
                                    <Route path='/skills' exact component={Skills} />
                                    <Route path='/contact-me' exact component={ContactMe} />
                                    <Route path='/projects'  component={Projects} />
                                    <Route path='/' exact component={AboutMe} />

                                </Switch>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.modal} modalClosed={this.closeModalHandler}>
                        <div className="text-center">
                            <h4 >
                                <i className="fa fa-cloud-download" aria-hidden="true"></i> <TransCap text='downloadMyCV' />
                        </h4>
                            <hr />
                            <h5><TransCap text='selectFile' /></h5>

                            {cvs}

                            <hr />
                            <button className="btn btn-light btn-sm" onClick={this.closeModalHandler}><TransCap text='close' /></button>
                        </div>
                    </Modal>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cvFiles: state.cvPersonalInfo.cvFiles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCvs: () => dispatch(actions.fetchCVs())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Layout, axios));