import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initalState = {
    data: [],
    loading: false,
    deleting: false,
    deletingFile: false,
    errorDeletingFile: null,
    posted: false,
    error: null,
    fileDeleted: false,
    progress: 0.0
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        // Add
        case actionTypes.LANGUAGE_ADD_INIT:
            return updateObject(state, { loading: false, posted: false, error: null });

        case actionTypes.LANGUAGE_ADD_START:
            return updateObject(state, { loading: true });

        case actionTypes.LANGUAGE_ADD_SUCCESS:
            const newLanugage = updateObject(action.data, { id: action.id });
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
                data: state.data.concat(newLanugage)
            });

        case actionTypes.LANGUAGE_ADD_FAIL:
            return updateObject(state, { loading: false, posted: false, error: action.error });

        // Update
        case actionTypes.LANGUAGE_UPDATE_SUCCESS:
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
            });


        // Fetch
        case actionTypes.LANGUAGE_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.LANGUAGE_FETCH_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });

        case actionTypes.LANGUAGE_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Find by ID
        case actionTypes.LANGUAGE_FINDBYID_START:
            return updateObject(state, { loading: true });

        case actionTypes.LANGUAGE_FINDBYID_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });
        case actionTypes.LANGUAGE_FINDBYID_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Delete
        case actionTypes.LANGUAGE_DELETE_START:
            return updateObject(state, { deleting: true, });

        case actionTypes.LANGUAGE_DELETE_SUCCESS:
            return updateObject(state, { deleting: false });

        case actionTypes.LANGUAGE_DELETE_FAIL:
            return updateObject(state, { deleting: false, error: action.error });

        // Upload
        case actionTypes.LANGUAGE_UPLOADFILE_START:
            return updateObject(state, { uploaded: false });

        case actionTypes.LANGUAGE_UPLOADFILE_PROGRESS:
            return updateObject(state, { progress: action.progress });

        case actionTypes.LANGUAGE_UPLOADFILE_SUCCESS:
            //  const newDataFile = updateObject(state.data, action.data);

            return updateObject(state, { uploaded: true, progress: 0.0 });

        case actionTypes.LANGUAGE_UPLOADFILE_FAIL:
            return updateObject(state, { uploaded: false, error: action.error });


        case actionTypes.LANGUAGE_DELETE_FILE_START:
            return updateObject(state, { deletingFile: true });

        case actionTypes.LANGUAGE_DELETE_FILE_SUCCESS:
            return updateObject(state, { fileDeleted: true, deletingFile: false });

        case actionTypes.LANGUAGE_DELETE_FILE_FAIL:
            return updateObject(state, { deletingFile: false, errorDeletingFile: action.errorDeletingFile });


        default:
            return state;
    }
};

export default reducer;