import React, { Component } from 'react';

import classes from './Maintenance.module.css';

import Img from '../../../assets/images/m.png';

class Maintenance extends Component {
    render() { 
        return ( 
            <div className={classes.Maintenance}>
                <div>
                    <h1> Maintenance Mode </h1>
                    <img src={Img} alt='' className='img-responsive' />
                </div>
            </div>
         );
    }
}
 
export default Maintenance;