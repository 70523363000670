import firebase from 'firebase/app';
import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyArO162YVaNTXHXy-VG2ffTTgE0LzueEvM",
    authDomain: "sharif-qasrawi.firebaseapp.com",
    databaseURL: "https://sharif-qasrawi.firebaseio.com",
    projectId: "sharif-qasrawi",
    storageBucket: "sharif-qasrawi.appspot.com",
    messagingSenderId: "878389250689",
    appId: "1:878389250689:web:d17e5cda3eb31b74bd5b80",
    measurementId: "G-FWXY9R953P"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const storage = firebase.storage();

export {
    storage,
    firebase as default
}