import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import counterpart from 'counterpart';

import withErrorHandler from '../../../hoc/withErrorHandler';
import axios from '../../../axios-firebase';
import * as actions from '../../../store/actions/index';

import Spinner from '../../../components/UI/Spinner/Spinner';
import TextHeader from '../../../components/UI/TextHeader/TextHeader';

import robot from '../../../assets/images/robot.png';

import TransCap from '../../../components/TransCap/TransCap';

import classes from './AboutMe.module.css';

class AboutMe extends Component {

    componentDidMount() {
        this.props.onFetchAboutMe();
    }


    // shouldComponentUpdate(nextProps, nextState) {
    //      return this.props.lang !== nextProps.lang || this.props.aboutMe !== nextProps.aboutMe;
    // }

    render() {
        const lang = counterpart.getLocale();
        let aboutMe = <Spinner size='100px' />

        if (!this.props.loading) {
            aboutMe = (
                <div>
                    {/* <h2>
                        <i className="fa fa-info-circle mb-2" aria-hidden="true"></i> About Me
                    </h2> */}

                    <TextHeader text={(<span><i className="fa fa-info-circle mb-2" aria-hidden="true"></i> <TransCap text='mainPages.home.aboutMe' /></span>)} />

                    <div>{ReactHtmlParser(lang === 'fr' ? this.props.aboutMe_FR : this.props.aboutMe_EN)}</div>
                    <br /><br />
                    {/* <h2>
                        <i className="fa fa-info-circle mb-2" aria-hidden="true"></i> About this website
                    </h2> */}

                    <TextHeader text={(<span><i className="fa fa-info-circle mb-2" aria-hidden="true"></i> <TransCap text='mainPages.home.aboutWebsite' /></span>)} />

                    <ul>
                        <li> <TransCap text='mainPages.home.developedBy' /></li>

                        <li> <TransCap text='mainPages.home.techsUsed' />
                            <ul>
                                <li>React 16.12.</li>
                                <li> CSS3. </li>
                                <li> Twitter Bootstrap. </li>
                                <li> fontawesome icons. </li>
                                <li> Google Firebase. </li>
                            </ul>
                        </li>
                        <li>
                            Version 1.0 (Beta)
                        </li>
                        <li>
                            <a href="/policy.html" target="_blank">
                                Privacy Policy
                            </a>
                        </li>
                    </ul>

                </div>
            );
        }

        return (
            <div className={classes.AboutMe}>
                {aboutMe}

                {/* <img src={robot} alt=""/> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        aboutMe_FR: state.cvPersonalInfo.aboutMe_FR,
        aboutMe_EN: state.cvPersonalInfo.aboutMe_EN,
        loading: state.cvPersonalInfo.loadingAboutMe
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchAboutMe: () => dispatch(actions.fetchAboutMe())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AboutMe, axios));