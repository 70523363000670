import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initalState = {
    data: [],
    project: {},
    loading: false,
    deleting: false,
    posted: false,
    error: null,
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        // Add
        case actionTypes.PROJECT_ADD_INIT:
            return updateObject(state, { loading: false, posted: false, error: null });

        case actionTypes.PROJECT_ADD_START:
            return updateObject(state, { loading: true });

        case actionTypes.PROJECT_ADD_SUCCESS:
            const newProject = updateObject(action.data, { id: action.id });
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
                data: state.data.concat(newProject)
            });

        case actionTypes.PROJECT_ADD_FAIL:
            return updateObject(state, { loading: false, posted: false, error: action.error });

        // Update
        case actionTypes.PROJECT_UPDATE_SUCCESS:
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
            });


        // Fetch
        case actionTypes.PROJECT_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.PROJECT_FETCH_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data.sort((a,b) => a.type.localeCompare(b.type))
            });

        case actionTypes.PROJECT_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Find by ID
        case actionTypes.PROJECT_FINDBYID_START:
            return updateObject(state, { loading: true });

        case actionTypes.PROJECT_FINDBYID_SUCCESS:
            return updateObject(state, {
                loading: false,
                project: action.project
            });
        case actionTypes.PROJECT_FINDBYID_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Delete
        case actionTypes.PROJECT_DELETE_START:
            return updateObject(state, { deleting: true, });

        case actionTypes.PROJECT_DELETE_SUCCESS:
            return updateObject(state, { deleting: false });

        case actionTypes.PROJECT_DELETE_FAIL:
            return updateObject(state, { deleting: false, error: action.error });

        default:
            return state;
    }
};

export default reducer;