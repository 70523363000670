import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utilities';

const initalState = {
    data: [],
    loading: false,
    deleting: false,
    posted: false,
    error: null
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
        // Add
        case actionTypes.SKILL_ADD_INIT:
            return updateObject(state, { loading: false, posted: false, error: null });

        case actionTypes.SKILL_ADD_START:
            return updateObject(state, { loading: true });

        case actionTypes.SKILL_ADD_SUCCESS:
            // const newSkill = updateObject(action.data, { id: action.id });
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
                // data: state.data.concat(newSkill)
            });

        case actionTypes.SKILL_ADD_FAIL:
            return updateObject(state, { loading: false, posted: false, error: action.error });

        // Update
        case actionTypes.SKILL_UPDATE_SUCCESS:
            return updateObject(state, {
                loading: false,
                posted: true,
                error: null,
            });

        
        // Fetch
        case actionTypes.SKILL_FETCH_START:
            return updateObject(state, { loading: true });

        case actionTypes.SKILL_FETCH_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });

        case actionTypes.SKILL_FETCH_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Find by ID
        case actionTypes.SKILL_FINDBYID_START:
            return updateObject(state, { loading: true });

        case actionTypes.SKILL_FINDBYID_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.data
            });
        case actionTypes.SKILL_FINDBYID_FAIL:
            return updateObject(state, { loading: false, error: action.error });


        // Delete
        case actionTypes.SKILL_DELETE_START:
            return updateObject(state, { deleting: true, });

        case actionTypes.SKILL_DELETE_SUCCESS:
            return updateObject(state, { deleting: false });

        case actionTypes.SKILL_DELETE_FAIL:
                return updateObject(state, { deleting: false, error: action.error });

        //

        default:
            return state;
    }
};

export default reducer;